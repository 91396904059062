import React from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  Navigate,
  useLocation, // Import useLocation
} from "react-router-dom";
import SignIn from "./pages/signin/SignIn";
import Dahsboard from "./pages/Dashboard/Dahsboard";
import SideMenu from "./components/SideMenu/SideMenu";
import Indicators from "./pages/Indicator/Indicators";
import StrategicPlan from "./pages/StrategicPlan/StrategicPlan";
import Objectives from "./pages/Objectives/Objectives";
import InitiativesTasks from "./pages/initiatives/InitiativesTasks";
import User from "./pages/userDepartment/User";
import { styled } from "@mui/material/styles";
import { UserProvider, useUser } from "./utils/UserContext";
import ResetPassword from "./pages/signin/ResetPassword";
import CssBaseline from "@mui/material/CssBaseline";
import { DrawerProvider, useDrawer } from "./utils/DrawerProvider";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const theme = createTheme();

const Layout = () => {
  const { drawerWidth: contextDrawerWidth } = useDrawer();

  const MainContent = styled("div")(({ theme, drawerWidth }) => ({
    marginLeft: drawerWidth,
    padding: theme.spacing(2),
    transition: "margin 0.3s ease-in-out",
    backgroundColor: "#F5F8FB",
    minHeight: "100vh",
    boxSizing: "border-box",
  }));

  return (
    <MainContent drawerWidth={contextDrawerWidth}>
      <SideMenu defaultDrawerWidth={contextDrawerWidth} />
      <Outlet />
    </MainContent>
  );
};

const ProtectedRoute = ({ children }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) return <p>Loading...</p>;

  if (!user && location.pathname !== "/login") {
    return <Navigate to="/login" />; // Removed replace
  }

  return children;
};

const CheckAuth = ({ children }) => {
  const { user, loading } = useUser();
  const location = useLocation();

  if (loading) return <p>Loading...</p>;

  if (user && location.pathname === "/login") {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      { index: true, element: <Navigate to="/dashboard" /> }, // Removed replace
      { path: "dashboard", element: <Dahsboard /> },
      { path: "indicators", element: <Indicators /> },
      { path: "strategicPlan", element: <StrategicPlan /> },
      { path: "objectives", element: <Objectives /> },
      { path: "initiativestasks", element: <InitiativesTasks /> },
      { path: "users", element: <User /> },
    ],
  },
  {
    path: "/login",
    element: (
      <CheckAuth>
        <SignIn />
      </CheckAuth>
    ),
  },
  { path: "/reset-password", element: <ResetPassword /> },
  { path: "*", element: <Navigate to="/login" /> }, // Removed replace
]);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <UserProvider>
        <DrawerProvider>
          <CssBaseline enableColorScheme />
          <RouterProvider router={router} /> {/* Directly use RouterProvider */}
        </DrawerProvider>
      </UserProvider>
    </ThemeProvider>
  );
}

export default App;
