import React, { createContext, useContext, useState, useEffect } from "react";
import { getSession } from "./auth";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    const token = document.cookie
      .split("; ")
      .find((row) => row.startsWith("access_token="))
      ?.split("=")[1];

    if (storedUser && token) {
      setUser(storedUser);
      setLoading(false);
    } else {
      const fetchSession = async () => {
        setLoading(true); // Set loading to true *before* the fetch

        try {
          const session = await getSession();

          if (session?.user) {
            // Check if session and user exist
            setUser(session.user);

            const isSecure = window.location.protocol === "https:";
            const sameSite =
              process.env.NODE_ENV === "development" ? "Strict" : "Strict"; // Or Lax for different ports locally

            document.cookie = `access_token=${
              session.token
            }; max-age=${31536000}; path=/; ${
              isSecure ? "Secure;" : ""
            } SameSite=${sameSite}`;
          } else {
            setUser(null); // Set user to null if no session or user returned
          }
        } catch (error) {
          setUser(null); // Set user to null on error as well
        } finally {
          setLoading(false); // Set loading to false in finally to handle both success and failure
        }
      };

      fetchSession();
    }
  }, []);

  useEffect(() => {
    if (user) {
      localStorage.setItem("user", JSON.stringify(user));
    } else {
      localStorage.removeItem("user");
    }
  }, [user]);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  const context = useContext(UserContext);
  return context;
};
