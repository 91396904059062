import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import AppTheme from "../../Theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import FlagIcon from "@mui/icons-material/Flag";
import MovingIcon from "@mui/icons-material/Moving";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dayjs from "dayjs";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import KPI from "./KPI";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import PaymentsIcon from "@mui/icons-material/Payments";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { IconButton } from "@mui/material";
import PageHeader from "../../components/assests/PageHeader";
import SpaceDashboardIcon from "@mui/icons-material/SpaceDashboard";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const proxy = process.env.REACT_APP_PROXY;

export default function General() {
  const [loading, setLoading] = useState(false);
  const [initativeRecords, setinItativeRecords] = useState([]);
  const [KPIsRecords, setKPIsRecords] = useState([]);
  const [years, setYears] = useState([]);
  const [budget, setBudget] = useState(0);
  const [cost, setCost] = useState(0);
  const [departments, setDepartments] = useState([]);
  const [selectedYears, setSelectedYears] = useState([dayjs().year()]);
  const [filteredInitiatives, setFilteredInitiatives] = useState([]); // Store filtered initiatives
  const [allYearsSelected, setAllYearsSelected] = useState(true);
  const [department, setDepartment] = useState("all"); // Default to "all"
  const [selectedDepartments, setSelectedDepartments] = useState([]);
  const [selectedDepartmentNames, setSelectedDepartmentNames] = useState([]);
  const [allDepartmentsSelected, setAllDepartmentsSelected] = useState(true);

  const navigate = useNavigate();

  const handleYearChange = (event) => {
    const selectedOptions = event.target.value;

    if (selectedOptions.includes("all")) {
      setAllYearsSelected(true);
      setSelectedYears(years.filter((year) => year !== "all"));
    } else {
      setAllYearsSelected(false);
      setSelectedYears(selectedOptions);
    }
  };

  const printRef = useRef(null);

  const handleDownloadPdf = async () => {
    setLoading(true);
    const element = printRef.current;
    if (!element) {
      return;
    }
    const clonedElement = element.cloneNode(true);

    // 2. Hide elements on the cloned element:
    const elementsToHide = clonedElement.querySelectorAll(
      ".hide-download-button, .hide-filters"
    );
    elementsToHide.forEach((el) => (el.style.display = "none"));

    const canvas = await html2canvas(element, { scale: 3 });
    const data = canvas.toDataURL("image/png");

    const pdf = new jsPDF({
      orientation: "portrait",
      unit: "mm",
      format: [1920, 1080], // Or 'a4' if you prefer, but keep consistent units
    });

    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();

    const margin = 10; // 10mm margin

    const imgWidthWithMargin = pdfWidth - 2 * margin;
    const imgHeightWithMargin =
      (imgProperties.height * imgWidthWithMargin) / imgProperties.width;

    pdf.addImage(
      data,
      "PNG",
      margin,
      margin,
      imgWidthWithMargin,
      imgHeightWithMargin
    );
    pdf.save("Dashboard.pdf");
    setLoading(false);
  };

  useEffect(() => {
    const fetchYears = async () => {
      try {
        const response = await axios.get(`${proxy}/plan/getAllPlans`);
        const data = response.data.data;
        const yearsArray = [];

        data.forEach((plan) => {
          const startYear = new Date(plan.Start_Date).getFullYear();
          const endYear = new Date(plan.End_Date).getFullYear();
          for (let year = startYear; year <= endYear; year++) {
            if (!yearsArray.includes(year)) {
              yearsArray.push(year);
            }
          }
        });

        const yearsWithAll = [...yearsArray, "all"];
        setYears(yearsWithAll);

        setSelectedYears(yearsArray); // Select all actual years
        setAllYearsSelected(true); // Check "All Years"
      } catch (error) {}
    };

    fetchYears();
  }, []);

  useEffect(() => {
    const fetchInitiatives = async () => {
      try {
        const response = await axios.get(
          `${proxy}/initiatives/getAllInitiative`
        );
        const data = response.data.data;

        const filteredInitiatives = data.filter((initiative) => {
          const startDate = new Date(initiative.Start_Date);
          const yearMatches = selectedYears.includes(startDate.getFullYear());

          let departmentMatches = true;
          if (!allDepartmentsSelected && selectedDepartments.length > 0) {
            let initiativeDepartmentIds = initiative.Department_IDs;

            // Robust handling of initiative.Department_IDs:
            if (Array.isArray(initiativeDepartmentIds)) {
              initiativeDepartmentIds = initiativeDepartmentIds
                .filter((id) => id != null)
                .map(String);
            } else if (typeof initiativeDepartmentIds === "string") {
              initiativeDepartmentIds =
                initiativeDepartmentIds.trim() !== ""
                  ? initiativeDepartmentIds.split(",").map((id) => id.trim())
                  : [];

              console.log("initiativeDepartmentIds", initiativeDepartmentIds);
            } else if (initiativeDepartmentIds == null) {
              // Handle null or undefined directly
              initiativeDepartmentIds = [];
            } else {
              initiativeDepartmentIds = []; // Or handle in a way that makes sense for your app
            }
            console.log("initiativeDepartmentIds", initiativeDepartmentIds);
            departmentMatches = initiativeDepartmentIds.some((deptId) => {
              const deptIdStr = String(deptId).trim(); // Trim here
              const match = selectedDepartments.map(String).includes(deptIdStr); // Trim and convert to string
              return match;
            });
          }

          const finalMatch = yearMatches && departmentMatches;
          return finalMatch;
        });

        setinItativeRecords(filteredInitiatives);
        setFilteredInitiatives(filteredInitiatives);
        const totalBudget = filteredInitiatives.reduce((sum, initiative) => {
          const budgetValue = Number(initiative.budget) || 0;
          return sum + budgetValue;
        }, 0);

        const totalCost = filteredInitiatives.reduce((sum, initiative) => {
          const costValue = Number(initiative.cost) || 0;
          return sum + costValue;
        }, 0);

        setBudget(totalBudget);
        setCost(totalCost);
      } catch (error) {
        setBudget(0);
        setCost(0);
      }
    };

    fetchInitiatives();
  }, [selectedYears, selectedDepartments, allDepartmentsSelected]);

  useEffect(() => {
    const fetchKPIs = async () => {
      try {
        const response = await axios.get(`${proxy}/kpi/getAllKPIs`);
        const kpis = response.data.data;

        // Filter KPIs by selected year
        const filteredKPIs = kpis.filter((kpi) => {
          const startDate = new Date(kpi.Start_Date);
          return selectedYears.includes(startDate.getFullYear());
        });

        setKPIsRecords(filteredKPIs);
      } catch (error) {}
    };

    fetchKPIs();
  }, [selectedYears]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${proxy}/department/getDep`);
        const depts = response.data || [];

        const departmentsWithAll = [
          { Department_ID: "all", Department_Name: "All Departments" },
          ...depts,
        ];
        setDepartments(departmentsWithAll);

        // Initialize selections (All selected by default)
        setSelectedDepartments(["all"]); // Start with "all"
        setSelectedDepartmentNames(["All Departments"]);
        setAllDepartmentsSelected(true);
      } catch (error) {
        console.error("Error fetching departments:", error);
      }
    };

    fetchDepartments();
  }, []);

  const handleDepartmentChange = (event) => {
    const selectedIds = event.target.value;

    if (selectedIds.includes("all")) {
      setAllDepartmentsSelected(true);
      setSelectedDepartments(["all"]);
      setSelectedDepartmentNames(["All Departments"]);
      setDepartment("all");
    } else {
      setAllDepartmentsSelected(false);
      setSelectedDepartments(selectedIds); // Directly set the selected IDs

      const selectedNames = departments
        .filter((dept) => selectedIds.includes(String(dept.Department_ID)))
        .map((dept) => dept.Department_Name);

      setSelectedDepartmentNames(selectedNames);
      setDepartment(selectedIds.length === 1 ? selectedIds[0] : "");
    }
  };

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Grid
        container
        spacing={1} // ... other Grid props
        ref={printRef} // Attach the ref HERE!
      >
        <Grid item xs={12}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              position: "relative", // Add this line
            }}
          >
            <PageHeader
              title="Dashboard"
              subTitle="Key Figures"
              icon={<SpaceDashboardIcon fontSize="large" />}
            />
            <Tooltip title={"Export Dashboard as PDF"} arrow placement="left">
              <IconButton
                onClick={handleDownloadPdf}
                sx={{
                  color: "white",
                  position: "absolute",
                  right: 10,
                  top: "50%",
                  transform: "translateY(-50%)",
                  "&:hover": {
                    backgroundColor: "transparent",
                    color: "white",
                  },
                }}
                className="hide-download-button" // Add this class
              >
                <CloudDownloadIcon />
              </IconButton>
            </Tooltip>
          </Box>
        </Grid>
        <Grid
          container
          spacing={1}
          display="flex"
          flexGrow="1"
          flexDirection="column"
          width="100%"
          flexWrap="nowrap"
          gap="1"
          sx={{ mt: 4 }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: 1,
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={1}
              display="flex"
              flexGrow="1"
              flexDirection="row"
              justifyContent="space-around"
              alignItems="stretch" // Ensure all items stretch to the same height
              width="100%"
              flexWrap="nowrap"
              gap="1"
            >
              <FormControl sx={{ width: "45%", height: 40, fontSize: "1rem" }}>
                <Select
                  labelId="year-select-label"
                  multiple
                  value={allYearsSelected ? ["all"] : selectedYears}
                  onChange={handleYearChange}
                  renderValue={(selected) =>
                    selected.includes("all") ? "All Years" : selected.join(", ")
                  }
                >
                  <MenuItem key="all" value="all">
                    <Checkbox checked={allYearsSelected} />
                    <ListItemText primary="All Years" />
                  </MenuItem>
                  {years
                    .filter((year) => year !== "all")
                    .map((year) => (
                      <MenuItem key={year} value={year}>
                        <Checkbox checked={selectedYears.includes(year)} />
                        <ListItemText primary={year} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>

              <FormControl sx={{ width: "45%", height: 40, fontSize: "1rem" }}>
                <Select
                  labelId="department-select-label"
                  id="department-select"
                  multiple
                  value={allDepartmentsSelected ? ["all"] : selectedDepartments}
                  onChange={handleDepartmentChange}
                  renderValue={(selected) => {
                    if (selected.includes("all")) {
                      return "All Departments";
                    } else {
                      // Map selected department IDs to their names
                      const selectedNames = selected.map((id) => {
                        const dept = departments.find(
                          (dept) => dept.Department_ID === id
                        );
                        return dept ? dept.Department_Name : id;
                      });
                      return selectedNames.join(", ");
                    }
                  }}
                >
                  <MenuItem key="all" value="all">
                    <Checkbox checked={allDepartmentsSelected} />
                    <ListItemText primary="All Departments" />
                  </MenuItem>
                  {departments
                    .filter((dept) => dept.Department_ID !== "all")
                    .map((dept) => (
                      <MenuItem
                        key={dept.Department_ID}
                        value={dept.Department_ID}
                      >
                        <Checkbox
                          checked={
                            allDepartmentsSelected ||
                            selectedDepartments.includes(dept.Department_ID)
                          }
                        />
                        <ListItemText primary={dept.Department_Name} />
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 1,
              width: "100%",
            }}
          >
            <Grid
              container
              spacing={2}
              justifyContent="center"
              width="100%"
              alignItems="center"
            >
              <Grid
                container
                margin="0"
                spacing={2}
                display="flex"
                flexGrow="1"
                justifyContent="center" // Centers the *inner* Grid container
                alignItems="center" // Vertically centers items in inner grid
                width="100%"
                flexWrap="nowrap"
                sx={{ mt: 2 }}
                flexDirection="row"
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                  margin="0"
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer",
                      width: "100%",
                      flexGrow: 1, // Key: Make the Paper grow
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => navigate("/initiativestasks")}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        width: "100%",
                        padding: 2,
                        marginBottom: 1,
                        display: "flex",
                        alignItems: "center", // Center horizontally in Paper
                        justifyContent: "space-between", // Center vertically in Paper
                        flexGrow: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h2"
                          component="div"
                          sx={{ color: "#0A5EB0", mb: 2 }}
                        >
                          {initativeRecords.length}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontSize: 18 }}
                        >
                          Number of Initiatives
                        </Typography>
                      </Box>
                      <FlagIcon sx={{ fontSize: 40, color: "#0A5EB0" }} />
                    </Paper>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      cursor: "pointer", // Ensure the cursor is pointer
                      width: "100%",
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                    onClick={() => navigate("/indicators")}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        width: "100%",
                        padding: 2,
                        marginBottom: 1,
                        display: "flex",
                        alignItems: "center", // Center horizontally in Paper
                        justifyContent: "space-between", // Center vertically in Paper
                        flexGrow: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h2"
                          component="div"
                          sx={{ color: "#0A5EB0", mb: 2 }}
                        >
                          {KPIsRecords.length}
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontSize: 18 }}
                        >
                          Number of KPIs
                        </Typography>
                      </Box>
                      <MovingIcon sx={{ fontSize: 40, color: "#0A5EB0" }} />
                    </Paper>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        width: "100%",
                        padding: 2,
                        marginBottom: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexGrow: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h2"
                          component="div"
                          sx={{ color: "#0A5EB0", mb: 2 }}
                        >
                          {budget}$
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontSize: 18 }}
                        >
                          Budget
                        </Typography>
                      </Box>
                      <AttachMoneyIcon
                        sx={{ fontSize: 40, color: "#0A5EB0" }}
                      />
                    </Paper>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "100%",
                      flexGrow: 1,
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Paper
                      elevation={3}
                      sx={{
                        width: "100%",
                        padding: 2,
                        marginBottom: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexGrow: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <Typography
                          variant="h2"
                          component="div"
                          sx={{ color: "#0A5EB0", mb: 2 }}
                        >
                          {cost}$
                        </Typography>
                        <Typography
                          variant="body2"
                          color="textSecondary"
                          sx={{ fontSize: 18 }}
                        >
                          Cost
                        </Typography>
                      </Box>
                      <PaymentsIcon sx={{ fontSize: 40, color: "#0A5EB0" }} />
                    </Paper>
                  </Box>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    padding: 1,
                    marginBottom: 1,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "22px",
                  }}
                >
                  Initiatives Status
                </Paper>
              </Grid>

              {["Completed", "In progress", "Delayed", "Cancelled"].map(
                (status, index) => {
                  const colors = ["#52b202", "#0A5EB0", "#EB5B00", "#FF2929"];
                  const statusMapping = {
                    Completed: "Completed",
                    "In progress": "In progress",
                    Delayed: "Delayed",
                    Cancelled: "Canceled",
                  };
                  const count = filteredInitiatives.filter(
                    (item) =>
                      item.Status.toLowerCase() ===
                      statusMapping[status].toLowerCase()
                  ).length;
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      textAlign="center"
                      key={index}
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Paper
                        elevation={3}
                        sx={{
                          width: "100%",
                          height: "250px",
                          padding: 1,
                          marginBottom: 1,
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "space-around",
                        }}
                      >
                        <Gauge
                          width={200}
                          height={200}
                          value={(count / initativeRecords.length) * 100} // Ensure the value is the percentage (0-100)
                          startAngle={-110}
                          endAngle={110}
                          sx={{
                            [`& .${gaugeClasses.valueText}`]: {
                              fontSize: 20,
                            },
                            [`& .${gaugeClasses.valueArc}`]: {
                              fill: colors[index], // Ensure this dynamically changes based on the status
                            },
                          }}
                          text={
                            ({ value }) => `${Math.round(value)}%` // Round the value for clarity
                          }
                        />

                        <Typography variant="body2">{status}</Typography>
                      </Paper>
                    </Grid>
                  );
                }
              )}

              <Grid item xs={12}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    padding: 1,
                    marginBottom: 2,
                    marginTop: 2,
                    display: "flex",
                    justifyContent: "center",
                    fontSize: "22px",
                  }}
                >
                  KPI Status
                </Paper>
              </Grid>
              <KPI />
              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    padding: 2,
                    marginBottom: 1,
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    maxHeight: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexDirection: "column", // Ensures content is stacked
                    minHeight: "200px", // Sets a minimum height
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ color: "#0A5EB0", overflow: "hidden" }}
                    >
                      Late Initiatives
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 18, overflow: "hidden" }}
                    >
                      <ul style={{ paddingLeft: "20px" }}>
                        {filteredInitiatives
                          .filter(
                            (initiative) =>
                              initiative.Status.toLowerCase() === "delayed"
                          )
                          .map((delayedInitiative, index) => (
                            <li
                              key={index}
                              style={{
                                wordBreak: "break-word", // Ensures long text wraps
                                overflowWrap: "break-word", // Handles long words breaking to a new line
                                whiteSpace: "normal", // Allows normal text wrapping
                              }}
                            >
                              {delayedInitiative.Initiative_Name ||
                                delayedInitiative.Initiative_Name ||
                                "Unnamed Initiative"}
                            </li>
                          ))}
                      </ul>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Paper
                  elevation={3}
                  sx={{
                    width: "100%",
                    padding: 2,
                    marginBottom: 1,
                    display: "flex",
                    alignItems: "start",
                    justifyContent: "space-between",
                    maxHeight: "200px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    flexDirection: "column", // Matches the layout
                    minHeight: "200px", // Ensures consistent height
                  }}
                >
                  <Box>
                    <Typography
                      variant="h6"
                      sx={{ color: "#0A5EB0", overflow: "hidden" }}
                    >
                      In Progress
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: 18, overflow: "hidden" }}
                    >
                      <ul style={{ paddingLeft: "20px" }}>
                        {filteredInitiatives
                          .filter(
                            (initiative) =>
                              initiative.Status.toLowerCase() === "in progress"
                          )
                          .map((delayedInitiative, index) => (
                            <li
                              key={index}
                              style={{
                                wordBreak: "break-word", // Ensures long text wraps
                                overflowWrap: "break-word", // Handles long words breaking to a new line
                                whiteSpace: "normal", // Allows normal text wrapping
                              }}
                            >
                              {delayedInitiative.Initiative_Name ||
                                delayedInitiative.Initiative_Name ||
                                "Unnamed Initiative"}
                            </li>
                          ))}
                      </ul>
                    </Typography>
                  </Box>
                </Paper>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </AppTheme>
  );
}
