import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import { Toolbar, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import UseTable from "../../components/assests/UseTable";
import InitiativesForm from "./InitiativesForm";
import AppTheme from "../../Theme/AppTheme";
import Notification from "../../components/assests/Notification";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import axios from "axios";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip

const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth",
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 230 ? 230 : 0,
  transition: "margin-left 0.3s ease",
  width: "100%",
  height: "100%", // Set height to 100vh
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1, // Allow it to grow
  marginRight: theme.spacing(1),
  minWidth: 400, // Prevent overflow when the container is small
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "Initiative_ID", label: "ID" },
  { id: "Objective_ID", label: "Objective" },
  { id: "Initiative_Name", label: "Initiative" },
  { id: "Description", label: "Description" },
  { id: "Department", label: "Department" },
  { id: "Start_Date", label: "Start Date" },
  { id: "End_Date", label: "End Date" },
  { id: "budget", label: "Budget" },
  { id: "cost", label: "Cost" },
  { id: "percentage", label: "Percentage" },
  { id: "Status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

function Initiatives({ drawerWidth, selectedObjective, ...props }) {
  const [initativeRecords, setinItativeRecords] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("InitiativeId");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [initiativeIdToDelete, setInitiativeIdToDelete] = useState(null);
  const [reloadInitiatives, setReloadInitiatives] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [filteredInitiatives, setFilteredInitiatives] = useState([]);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { TblContainer } = UseTable(initativeRecords, headCells);

  const showNotification = (notification) => {
    setNotify(notification);
  };

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${proxy}/department/getDep`);
        setDepartments(response.data);
      } catch (error) {}
    };
    fetchDepartments();
  }, []);

  const getObjectivetName = (objectiveId) => {
    const objective = objectives.find(
      (objective) => objective.Strategic_Objectives_ID === objectiveId
    );
    return objective ? objective.Description : "Unknown";
  };

  const getDepartmentName = (departmentId) => {
    const department = departments.find(
      (dep) => dep.Department_ID === departmentId
    );
    return department ? department.Department_Name : "Unknown";
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const sortedRecords = useMemo(() => {
    const recordsToUse = selectedObjective
      ? filteredInitiatives
      : initativeRecords;
    return [...recordsToUse].sort((a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return order === "asc" ? -1 : 1;
      }
      if (a[orderBy] > b[orderBy]) {
        return order === "asc" ? 1 : -1;
      }
      return 0;
    });
  }, [
    order,
    orderBy,
    selectedObjective,
    filteredInitiatives,
    initativeRecords,
  ]);

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((initiative) => {
      return (
        (initiative.Description &&
          initiative.Description.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Initiative_Name &&
          initiative.Initiative_Name.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Objective_ID &&
          getObjectivetName(initiative.Objective_ID)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Department_ID &&
          getDepartmentName(initiative.Department_ID)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Status &&
          initiative.Status.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Start_Date &&
          formatDate(initiative.Start_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.End_Date &&
          formatDate(initiative.End_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Initiative_ID &&
          initiative.Initiative_ID.toString().includes(searchInput)) ||
        (initiative.End_Date &&
          formatDate(initiative.End_Date)
            .toLowerCase()
            .includes(searchInput.toLowerCase()))
      );
    });
  }, [sortedRecords, searchInput]);

  useEffect(() => {
    const fetchInitiatives = async () => {
      try {
        const response = await axios.get(
          `${proxy}/initiatives/getAllInitiative`
        );
        setinItativeRecords(response.data.data);
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to fetch plans",
          type: "error",
        });
      }
    };
    fetchInitiatives();
  }, [reloadInitiatives]);

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (initiativeIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/initiatives/deleteInitiative/${initiativeIdToDelete}`
        );
        if (response && response.data) {
          setReloadInitiatives((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete plan",
          type: "error",
        });
      } finally {
        setInitiativeIdToDelete(null); // Reset after delete
      }
    }
  };

  const confirmDeleteObjective = (Initiative_ID) => {
    setInitiativeIdToDelete(Initiative_ID);
    setOpenConfirmDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  useEffect(() => {
    // Filter initiatives based on selectedObjective
    if (selectedObjective) {
      const filtered = initativeRecords.filter((initiative) => {
        return (
          initiative.Objective_ID === selectedObjective.Strategic_Objectives_ID
        );
      });
      setFilteredInitiatives(filtered);
    } else {
      setFilteredInitiatives(initativeRecords); // Show all initiatives if no objective is selected
    }
  }, [selectedObjective, initativeRecords]);

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <Toolbar
          spacing={0}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            maxWidth: "100%",
            position: "relative",
            m: 0,
            p: 0,
            mt: 0,
            mb: 1,
          }}
        >
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%", mt: 0, height: "auto" }}
              id="search-initiative-input"
              placeholder="Search Initiatives"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>

          <NewButton>
            <Controls.Button
              text="Add New Initiative"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopup(true);
              }}
              sx={{ minWidth: "200px" }}
            />
          </NewButton>
        </Toolbar>

        <Grid
          container
          spacing={0}
          sx={{
            maxWidth: "100%",
            width: "100%",
            height: "100%",
            minHeight: "100%",
            justifyContent: "center",
            alignItems: "center",
            m: 0,
            p: 0,
          }}
        >
          {filteredRecords.map((item, index) => {
            const serialNumber = index + 1;
            const departmentIds = item.Department_IDs.split(",").map((id) =>
              parseInt(id, 10)
            );
            const departmentNames = departmentIds.map((id) =>
              getDepartmentName(id)
            );
            const departmentString = departmentNames.join(", ");

            return (
              <Grid
                item
                xs={12}
                key={item.Initiative_ID}
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "stretch", // Stretch cards vertically
                  mb: 1,
                }}
              >
                <Card
                  variant="outlined"
                  sx={{
                    minHeight: "100%",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    boxSizing: "border-box",
                    m: 0,
                    p: 2,
                    flexGrow: 1,
                  }}
                >
                  <CardContent
                    sx={{ width: "100%", height: "auto", m: 0, p: 1 }}
                  >
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{
                        color: "text.secondary",
                        mt: 0,
                        wordWrap: "break-word", // or overflowWrap: "break-word"
                      }}
                    >
                      {item.Initiative_Name}
                    </Typography>

                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      ID: {serialNumber}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Objective: {getObjectivetName(item.Objective_ID)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Description: {item.Description}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Department: {departmentString || "Unknown"}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Start Date: {formatDate(item.Start_Date)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      End Date: {formatDate(item.End_Date)}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Budget: {item.budget}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Cost: {item.cost}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Percentage:{" "}
                      {item.percentage == null
                        ? ""
                        : `${item.percentage || 0}%`}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ color: "text.secondary", mt: 1 }}
                    >
                      Status: {item.Status}
                    </Typography>
                  </CardContent>{" "}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      pr: 1,
                      justifyContent: "flex-end",
                    }}
                  >
                    <Controls.ActionButton onClick={() => openInPopup(item)}>
                      <Tooltip title={"Edit"} arrow placement="left">
                        <EditOutlinedIcon fontSize="small" />
                      </Tooltip>
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="error"
                      onClick={() => confirmDeleteObjective(item.Initiative_ID)}
                    >
                      <Tooltip title={"Delete"} arrow placement="right">
                        <CloseIcon fontSize="small" sx={{ color: "red" }} />
                      </Tooltip>
                    </Controls.ActionButton>
                  </Box>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </ContentContainer>

      <Popup
        title="Initiative Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <InitiativesForm
          recordForEdit={recordForEdit}
          showNotification={showNotification}
          onSubmit={() => setReloadInitiatives((prev) => !prev)}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <ConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        DialogName="Delete Initiative Confirmation"
        DialogText="Are you sure you want to delete this Initiative?"
        onConfirm={() => {
          handleConfirmDelete();
          showNotification({
            isOpen: true,
            message: "Initiative deleted successfully",
            type: "success",
          });
        }}
        onClose={() => setOpenConfirmDialog(false)}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </AppTheme>
  );
}

export default Initiatives;
