import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import { Toolbar, InputAdornment } from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import AppTheme from "../../Theme/AppTheme";
import ObjectiveForm from "./ObjectiveForm";
import axios from "axios";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import Notification from "../../components/assests/Notification";
import EmojiObjectsIcon from "@mui/icons-material/EmojiObjects";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip
import StrategicForm from "../StrategicPlan/StrategicForm";
import Initiatives from "../initiatives/Initiatives";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Button from "@mui/material/Button";
import { uploadFile } from "./FileUpload";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";

const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth", // Exclude drawerWidth prop from being passed to DOM
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
  overflow: "hidden",
}));

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 0,
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const formatDate = (dateString) => {
  if (!dateString) return "";
  const date = new Date(dateString);
  return new Intl.DateTimeFormat("en-GB", {
    day: "2-digit",
    month: "short",
    year: "numeric",
  }).format(date);
};

export default function Objectives({ drawerWidth, ...props }) {
  const [objectivesRecords, setObjectivesRecords] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupPlan, setOpenPopupPlan] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEditPlan, setRecordForEditPlan] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Strategic_Objectives_ID");
  const [reloadObjective, setReloadObjective] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [objectiveIdToDelete, setObjectiveIdToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [selectedObjective, setSelectedObjective] = useState(null);
  const [objectiveDetails, setObjectiveDetails] = useState(null);
  const [plansRecords, setPlansRecords] = useState([]);
  const [file, setFile] = useState(null);
  const [message, setMessage] = useState("");

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0]; // Get the selected file
    setFile(selectedFile); // Set the file state
    e.preventDefault();

    if (!selectedFile) {
      setMessage("Please select a file before uploading.");
      return;
    }

    try {
      const result = await uploadFile(selectedFile); // Use the selected file
      console.log(result);
      setMessage(result);
    } catch (error) {
      console.log(error);
      setMessage(error.message); // Set error message
    }
  };

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleEditPlan = () => {
    setOpenPopupPlan(true);
    if (plansRecords.length === 0) {
      setRecordForEditPlan(null); // Set recordForEdit to null if no plans
    } else {
      setRecordForEditPlan(plansRecords[0]); //  Set recordForEdit to the first plan (or whichever you intend to edit)
    }
  };

  const downloadFile = async () => {
    try {
      const response = await axios.get(`${proxy}/file/getFile`, {
        responseType: "blob",
      });
      const blob = new Blob([response.data], {
        type: response.headers["content-type"],
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute(
        "download",
        response.headers["content-disposition"].split("filename=")[1]
      ); // Extract filename from Content-Disposition header
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(link.href);
    } catch (error) {}
  };

  useEffect(() => {
    const fetchPlans = async () => {
      try {
        const response = await axios.get(`${proxy}/plan/getAllPlans`);
        setPlansRecords(response.data.data);
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to fetch plans",
          type: "error",
        });
      }
    };
    fetchPlans();
  }, []);

  const showNotification = (notification) => {
    setNotify(notification);
  };

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectivesRecords(response.data.data);
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to fetch plans",
          type: "error",
        });
      }
    };

    fetchObjectives();
  }, [reloadObjective]);

  const sortedRecords = [...objectivesRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const confirmDeleteObjective = (Strategic_Objectives_ID) => {
    setObjectiveIdToDelete(Strategic_Objectives_ID);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (objectiveIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/objective/deleteObjective/${objectiveIdToDelete}`
        );
        if (response && response.data) {
          setReloadObjective((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete plan",
          type: "error",
        });
      } finally {
        setObjectiveIdToDelete(null); // Reset after delete
      }
    }
  };

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((objective) => {
      return (
        objective.Description &&
        objective.Description.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
  }, [sortedRecords, searchInput]);

  const handleObjectiveClick = async (objective) => {
    setSelectedObjective(objective);
    setObjectiveDetails(null);
    // setFilteredTasks([]);

    try {
      const initiativesResponse = await axios.get(
        `${proxy}/initiatives/getAllInitiative`
      );
      const allInitiatives = initiativesResponse.data.data;

      const selectedDetails = allInitiatives.find(
        (item) => item.Initiative_ID === objective.Initiative_ID
      );
      setObjectiveDetails(selectedDetails);
    } catch (error) {}
  };

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <PageHeader
          title="Objectives Page"
          subTitle="Define Startegic Objectives"
          icon={<EmojiObjectsIcon fontSize="large" />}
        />
        <Box
          sx={{
            flexGrow: 1,
            mt: 0,
            display: "flex",
            flexDirection: "column",
            height: "100vh",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE and Edge
          }}
        >
          <Grid
            container
            spacing={1}
            display="flex"
            flexGrow="1"
            flexDirection="row"
            width="100%"
            flexWrap="nowrap"
            gap="1"
            sx={{
              mt: 0,
              height: "100vh",
              maxHeight: "100vh",
              minHeight: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                minHeight: "93vh",
                maxHeight: "93vh",
                overflowY: "auto",
                borderRight: "1px solid #ddd",
                p: 1,
                width: "100%",
                minWidth: "40%",
                m: 0,
                maxWidth: "45%",
                display: "flex",
                flexDirection: "column",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none",
                msOverflowStyle: "none",
              }}
            >
              <Typography variant="h4" gutterBottom>
                Objectives
              </Typography>

              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "column", // This is the KEY change!
                  width: "100%",
                }}
              >
                <Toolbar
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <SearchInput sx={{ m: 0, minWidth: "200px", p: 0 }}>
                    <Controls.Input
                      id="search-objective-input"
                      placeholder="Search Objective"
                      value={searchInput}
                      sx={{ minWidth: "200px", p: 0 }}
                      onChange={(e) => setSearchInput(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Controls.ActionButton
                              color="secondary"
                              onClick={() => setSearchInput("")}
                            >
                              <CloseIcon fontSize="small" />
                            </Controls.ActionButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </SearchInput>

                  <NewButton sx={{ minWidth: "200px", p: 0 }}>
                    <Controls.Button
                      sx={{ minWidth: "200px" }}
                      text="Add Objective"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setRecordForEdit(null);
                        setOpenPopup(true);
                      }}
                    />
                  </NewButton>
                </Toolbar>

                {filteredRecords.map((objective) => (
                  <Card
                    key={objective.Strategic_Objectives_ID}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      minHeight: 50,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      m: 1,
                      mt: 0,
                    }}
                    onClick={() => setSelectedObjective(objective)}
                  >
                    <CardContent sx={{ flexGrow: 1, width: "100%" }}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{ fontWeight: "normal" }}
                      >
                        Description:{" "}
                        {objective.Description || "Unnamed Description"}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", mt: 1 }}
                      >
                        Priority: {objective.priority || "Unknown"}
                      </Typography>
                    </CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        pr: 1,
                        justifyContent: "center",
                      }}
                    >
                      <Controls.ActionButton
                        onClick={(event) => {
                          event.stopPropagation();
                          openInPopup(objective);
                        }}
                      >
                        <Tooltip title={"Edit"} arrow placement="left">
                          <EditOutlinedIcon fontSize="small" />
                        </Tooltip>
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="error"
                        onClick={(event) => {
                          event.stopPropagation(); // Prevent card click
                          confirmDeleteObjective(
                            objective.Strategic_Objectives_ID
                          );
                          // setDialogName("Initiative");
                        }}
                      >
                        <Tooltip title={"Delete"} arrow placement="left">
                          <CloseIcon fontSize="small" sx={{ color: "red" }} />
                        </Tooltip>
                      </Controls.ActionButton>
                    </Box>
                  </Card>
                ))}
              </Grid>
            </Box>
            <Grid
              container
              spacing={1}
              display="flex"
              flexGrow="1"
              flexDirection="column"
              width="100%"
              flexWrap="nowrap"
              gap="1"
              sx={{ ml: 1, p: 1, height: "100%" }}
              maxWidth="55%"
              minWidth="55%"
            >
              <Box
                sx={{
                  width: "100%",
                  maxHeight: "55%",
                  overflowY: "auto",
                  borderBottom: "1px solid #ddd",
                  pb: 1,
                  display: "flex",
                  flexDirection: "column",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none", // Firefox
                  msOverflowStyle: "none", // IE and Edge
                }}
              >
                <Toolbar
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    p: 0,
                    m: 0,
                  }}
                >
                  <Typography variant="h4" gutterBottom>
                    Strategic Plan Details
                  </Typography>
                  <Tooltip
                    title={
                      plansRecords.length > 0
                        ? "A strategic plan already exists."
                        : "Add New Plan"
                    }
                    arrow
                    placement="left"
                  >
                    <NewButton sx={{ minWidth: "200px", p: 0 }}>
                      <Controls.Button
                        sx={{ minWidth: "200px" }}
                        text={
                          plansRecords.length === 0 ? "Add Plan" : "Edit Plan"
                        }
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={() => {
                          handleEditPlan();
                        }}
                      />
                    </NewButton>
                  </Tooltip>
                </Toolbar>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "100%", // Grid container takes full width of Box
                    maxHeight: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12} // Ensure the Grid item takes full width
                    key={
                      plansRecords
                        ? plansRecords.Strategic_Plan_ID
                        : "no-selection"
                    }
                    sx={{ width: "100%", height: "100%" }}
                  >
                    <Card
                      variant="outlined"
                      sx={{
                        width: "100%", // Card takes full width of Grid item
                        minHeight: 50,
                        display: "flex",
                        flexDirection: "column",
                        height: "100%", // Card takes full height of Grid item
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
                        {plansRecords.map(
                          (
                            plan // Conditionally render if details exist
                          ) => (
                            <>
                              <Tooltip title={"Vision"} arrow placement="left">
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "text.secondary",
                                    mt: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <RemoveRedEyeIcon sx={{ mr: 1 }} />{" "}
                                  {plan.vision}
                                </Typography>
                              </Tooltip>
                              <Tooltip title={"Mission"} arrow placement="left">
                                <Typography
                                  variant="body2"
                                  sx={{
                                    color: "text.secondary",
                                    mt: 1,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  <CrisisAlertIcon sx={{ mr: 1 }} />{" "}
                                  {plan.mission}
                                </Typography>
                              </Tooltip>
                              <Typography
                                variant="body2"
                                sx={{ color: "text.secondary", mt: 1 }}
                              >
                                Description: {plan.strategic_plan_Description}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "text.secondary", mt: 1 }}
                              >
                                Start Date: {formatDate(plan.Start_Date)}
                              </Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: "text.secondary", mt: 1 }}
                              >
                                End Date: {formatDate(plan.End_Date)}
                              </Typography>
                            </>
                          )
                        )}
                      </CardContent>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudUploadIcon />}
                          sx={{ width: "100%", m: 1 }}
                        >
                          Upload Plan File
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange}
                            multiple
                          />
                        </Button>
                        <Button
                          component="label"
                          role={undefined}
                          variant="contained"
                          tabIndex={-1}
                          startIcon={<CloudDownloadIcon />}
                          sx={{ width: "100%", m: 1 }}
                          onClick={downloadFile} // Call downloadFile function
                        >
                          Download Plan File
                        </Button>
                      </div>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  height: "100%",
                  maxHeight: "50%",
                  overflowX: "none", // Prevent horizontal scrolling
                  overflowY: "auto",
                  p: 1,
                  maxWidth: "100%",
                  display: "flex",
                  flexDirection: "column", // This is correct
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                <Typography variant="h4" gutterBottom sx={{ mb: 0, ml: 2 }}>
                  Initiatives
                </Typography>
                <Box sx={{ maxWidth: "100%", height: "100%" }}>
                  <Initiatives selectedObjective={selectedObjective} />
                </Box>
              </Box>
            </Grid>
            <Popup
              title="Objective Form"
              openPopup={openPopup}
              setOpenPopup={setOpenPopup}
            >
              <ObjectiveForm
                recordForEdit={recordForEdit}
                onSubmit={() => setReloadObjective((prev) => !prev)}
                setOpenPopup={setOpenPopup}
                showNotification={showNotification}
              />
            </Popup>
            <Popup
              title="Stategic Plan Form"
              openPopup={openPopupPlan}
              setOpenPopup={setOpenPopupPlan}
            >
              <StrategicForm
                recordForEdit={recordForEditPlan} // Pass the state variable
                setOpenPopup={setOpenPopupPlan}
                showNotification={showNotification}
              />
            </Popup>

            <ConfirmationDialog
              open={openConfirmDialog}
              setOpen={setOpenConfirmDialog}
              DialogName="Delete Objectives Confirmation"
              DialogText="Are you sure you want to delete this Objective?"
              onConfirm={() => {
                handleConfirmDelete();
                showNotification({
                  isOpen: true,
                  message: "Objective deleted successfully",
                  type: "success",
                });
              }}
              onClose={() => setOpenConfirmDialog(false)}
            />
            <Notification notify={notify} setNotify={setNotify} />
          </Grid>
        </Box>
      </ContentContainer>
    </AppTheme>
  );
}
