import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppTheme from "../../Theme/AppTheme";
import {
  chartsCustomizations,
  dataGridCustomizations,
  datePickersCustomizations,
  treeViewCustomizations,
} from "./theme/customizations";
import General from "./General";

const xThemeComponents = {
  ...chartsCustomizations,
  ...dataGridCustomizations,
  ...datePickersCustomizations,
  ...treeViewCustomizations,
};

export default function Dashboard(props) {
  // const generalRef = useRef();

  // const generatePDF = () => {
  //   const element = generalRef.current;
  //   html2pdf(element, { margin: 20 });
  // };

  return (
    <AppTheme {...props} themeComponents={xThemeComponents}>
      <CssBaseline enableColorScheme />
      <General /> {/* Pass the correct ref to General */}
    </AppTheme>
  );
}
