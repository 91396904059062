import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
  TableContainer,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import UseTable from "../../components/assests/UseTable";
import AppTheme from "../../Theme/AppTheme";
import KPIForm from "./KPIForm";
import axios from "axios";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import Notification from "../../components/assests/Notification";
import MovingIcon from "@mui/icons-material/Moving";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip
import Box from "@mui/material/Box";

const proxy = process.env.REACT_APP_PROXY;

// Styled components

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 0,
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "KPI_Id", label: "ID", width: "50px" },
  { id: "KPI_Name", label: "KPI", width: "150px" },
  { id: "Objective_Id", label: "Objective", width: "150px" },
  { id: "Start_Date", label: "Start Date", width: "100px" },
  { id: "End_Date", label: "End Date", width: "100px" },
  { id: "Target_Value", label: "Target Value", width: "100px" },
  { id: "Actual_Value", label: "Actual Value", width: "100px" },
  { id: "frequency", label: "Frequency", width: "100px" },
  { id: "Measurement_unit", label: "Measurement", width: "100px" },
  { id: "Direction", label: "Direction", width: "100px" },
  { id: "Functionality", label: "Functionality", width: "150px" },
  { id: "actions", label: "Actions", disableSorting: true, width: "100px" },
];

export default function KPI({ drawerWidth, ...props }) {
  const [KPIsRecords, setKPIsRecords] = useState([]);
  const [openPopupKPI, setOpenPopupKPI] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Strategic_Plan_ID");
  const [reloadKPI, setreloadKPI] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [KPIIdToDelete, setKPIIdToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });
  const [objectives, setObjectives] = useState([]);

  const { TblContainer } = UseTable(KPIsRecords, headCells);

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  const fetchKPIs = async () => {
    try {
      const response = await axios.get(`${proxy}/kpi/getAllKPIs`);
      setKPIsRecords(response.data.data);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to fetch KPIs",
        type: "error",
      });
    }
  };

  const showNotification = (notification) => {
    setNotify(notification);
  };

  const getObjectivetName = (objectiveId) => {
    const objective = objectives.find(
      (objective) => objective.Strategic_Objectives_ID === objectiveId
    );
    return objective ? objective.Description : "Unknown";
  };

  useEffect(() => {
    fetchKPIs();
  }, [reloadKPI]);

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };

  const sortedRecords = [...KPIsRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopupKPI(true);
  };

  const confirmDeleteKPI = (KPI_Id) => {
    setKPIIdToDelete(KPI_Id);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (KPIIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/kpi/deleteKPI/${KPIIdToDelete}`
        );
        if (response && response.data) {
          setreloadKPI((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete KPI",
          type: "error",
        });
      } finally {
        setKPIIdToDelete(null); // Reset after delete
      }
    }
  };

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((kpi) => {
      return (
        kpi.KPI_Name &&
        kpi.KPI_Name.toLowerCase().includes(searchInput.toLowerCase())
      );
    });
  }, [sortedRecords, searchInput]);

  return (
    <AppTheme {...props}>
      <Box sx={{ width: "100%" }}>
        <PageHeader
          title="KPI Page"
          subTitle="Define KPIs for your organization/Company"
          icon={<MovingIcon fontSize="large" />}
        />
        <Toolbar
          sx={{ display: "flex", alignItems: "center", maxWidth: "100%" }}
        >
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%" }}
              id="search-KPI-data"
              placeholder="Search KPIs"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>
          <NewButton>
            <Controls.Button
              text="Add New KPI"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopupKPI(true);
              }}
            />
          </NewButton>
        </Toolbar>

        <TableContainer
          sx={{
            maxWidth: "100%",
            width: "100%",
            overflowX: "auto",
            maxHeight: "70vh",
            "&::-webkit-scrollbar": {
              display: "none", // Hide scrollbars in WebKit browsers (Chrome, Safari)
            },
            msOverflowStyle: "none", // Hide scrollbars in IE and Edge
            scrollbarWidth: "none", // Hide scrollbars in Firefox
          }}
        >
          <TblContainer
            sx={{
              width: "100%",
              tableLayout: "fixed",
            }}
          >
            <TableHead sx={{ width: "100%" }}>
              <TableRow sx={{ width: "100%" }}>
                {headCells.map((headCell) => (
                  <TableCell
                    sx={{
                      maxWidth: headCell.width ? headCell.width : "auto", // Use the width defined in headCells
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                    }}
                    key={headCell.id}
                    sortDirection={orderBy === headCell.id ? order : false}
                  >
                    {headCell.disableSorting ? (
                      headCell.label
                    ) : (
                      <TableSortLabel
                        active={orderBy === headCell.id}
                        direction={orderBy === headCell.id ? order : "asc"}
                        onClick={(event) =>
                          handleRequestSort(event, headCell.id)
                        }
                      >
                        {headCell.label}
                      </TableSortLabel>
                    )}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredRecords.map((item, index) => {
                const serialNumber = index + 1;
                return (
                  <TableRow
                    key={item.KPI_Id}
                    sx={{
                      backgroundColor: "white",
                      borderWidth: 2,
                      borderColor: "black",
                    }}
                  >
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {serialNumber}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.KPI_Name}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {getObjectivetName(item.Objective_Id)}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {formatDate(item.Start_Date)}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {formatDate(item.End_Date)}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "100px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.Target_Value}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.Actual_Value}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "10px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.frequency}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.Measurement_unit}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.Direction === "High" ? (
                        <Tooltip title={"High"} arrow placement="right">
                          <ArrowCircleUpIcon sx={{ color: "#52b202" }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title={"Low"} arrow placement="right">
                          <ArrowCircleDownIcon sx={{ color: "#FF2929" }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item.Functionality}
                    </TableCell>
                    <TableCell
                      sx={{
                        maxWidth: "200px",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                      }}
                    >
                      <Controls.ActionButton
                        color="primary"
                        onClick={() => {
                          openInPopup(item);
                        }}
                      >
                        <Tooltip title={"Edit"} arrow placement="left">
                          <EditOutlinedIcon fontSize="small" />
                        </Tooltip>
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="error"
                        onClick={() => confirmDeleteKPI(item.KPI_Id)}
                      >
                        <Tooltip title={"Delete"} arrow placement="right">
                          <CloseIcon fontSize="small" sx={{ color: "red" }} />
                        </Tooltip>
                      </Controls.ActionButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </TblContainer>
        </TableContainer>
      </Box>
      <Popup
        title="KPI Form"
        openPopup={openPopupKPI}
        setOpenPopup={setOpenPopupKPI}
      >
        <KPIForm
          recordForEdit={recordForEdit}
          onSubmit={() => setreloadKPI((prev) => !prev)}
          setOpenPopup={setOpenPopupKPI}
          showNotification={showNotification}
        />
      </Popup>
      <ConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        DialogName="Delete KPI Confirmation"
        DialogText="Are you sure you want to delete this KPI?"
        onConfirm={() => {
          handleConfirmDelete();
          showNotification({
            isOpen: true,
            message: "KPI deleted successfully",
            type: "success",
          });
        }}
        onClose={() => setOpenConfirmDialog(false)}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </AppTheme>
  );
}
