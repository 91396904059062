import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { TextField, FormControl, InputLabel } from "@mui/material";

const proxy = process.env.REACT_APP_PROXY;

const planFValues = {
  Strategic_Plan_ID: 0,
  strategic_plan_Description: "",
  vision: "",
  mission: "",
  Start_Date: null,
  End_Date: null,
};
dayjs.extend(utc);
export default function StrategicForm(props) {
  const { recordForEdit, onSubmit, showNotification } = props;
  const [loading, setLoading] = useState(false);

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEdit || planFValues,
    true
  );

  useEffect(() => {
    if (recordForEdit) {
      const startDate = dayjs(recordForEdit.Start_Date);
      const endDate = dayjs(recordForEdit.End_Date);
      setValues({
        Strategic_Plan_ID: recordForEdit.Strategic_Plan_ID,
        strategic_plan_Description: recordForEdit.strategic_plan_Description,
        vision: recordForEdit.vision,
        mission: recordForEdit.mission,
        Start_Date: startDate.isValid() ? startDate : null,
        End_Date: endDate.isValid() ? endDate : null,
      });
    } else {
      resetForm();
    }
  }, [recordForEdit, setValues]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const planData = {
      Strategic_Plan_ID: recordForEdit ? recordForEdit.Strategic_Plan_ID : null,
      strategic_plan_Description: values.strategic_plan_Description,
      vision: values.vision,
      mission: values.mission,
      Start_Date: values.Start_Date.format("YYYY-MM-DD"),
      EndDate: values.End_Date.format("YYYY-MM-DD"),
    };
    try {
      if (recordForEdit) {
        const res = await axios.put(
          `${proxy}/plan/updatePlan/${recordForEdit.Strategic_Plan_ID}`,
          planData
        );
      } else {
        const res = await axios.post(`${proxy}/plan/savePlan`, planData);
      }
      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEdit
          ? "Plan updated successfully!"
          : "Plan submitted successfully!",
        type: "success",
      });
      props.setOpenPopup(false);
    } catch (error) {
      if (error.response.status === 409) {
        showNotification({
          isOpen: true,
          message: "Plan already exists.",
          type: "warning",
        });
      } else {
        showNotification({
          isOpen: true,
          message: "Failed to submit plan data.",
          type: "error",
        });
      }
    } finally {
      setLoading(false); // Set loading back to false when done
    }
  };
  const handleReset = () => {
    resetForm();
  };

  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <Controls.Input
              required
              label="Name"
              name="strategic_plan_Description"
              value={values.strategic_plan_Description}
              onChange={handleInputChange}
              error={errors.strategic_plan_Description}
              fullWidth
              autoFocus
              margin="normal"
              sx={{
                height: 100, // Set the outer height
                "& .MuiInputBase-root": { height: "100%" }, // Ensure inner input spans full height
              }}
              multiline
              rows={5}
              InputLabelProps={{
                shrink: true, // Ensure the label stays above the input
              }}
            />
            <Controls.Input
              required
              label="Vision"
              name="vision"
              value={values.vision}
              onChange={handleInputChange}
              error={errors.vision}
              fullWidth
              autoFocus
              margin="normal"
              sx={{
                height: 100, // Set the outer height
                "& .MuiInputBase-root": { height: "100%" }, // Ensure inner input spans full height
              }}
              multiline
              rows={5}
              InputLabelProps={{
                shrink: true, // Ensure the label stays above the input
              }}
            />
            <Controls.Input
              required
              label="Mission"
              name="mission"
              value={values.mission}
              onChange={handleInputChange}
              error={errors.mission}
              fullWidth
              autoFocus
              margin="normal"
              sx={{
                height: 100, // Set the outer height
                "& .MuiInputBase-root": { height: "100%" }, // Ensure inner input spans full height
              }}
              multiline
              rows={5}
              InputLabelProps={{
                shrink: true, // Ensure the label stays above the input
              }}
            />

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormControl fullWidth sx={{ marginBottom: 3, marginTop: 2 }}>
                <InputLabel shrink htmlFor="start-date-picker">
                  Start Date
                </InputLabel>
                <DatePicker
                  name="Start_Date"
                  value={values.Start_Date ? dayjs(values.Start_Date) : null} // Ensure it's a valid dayjs object or null
                  error={false}
                  onChange={(newValue) =>
                    setValues({ ...values, Start_Date: newValue })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="start-date-picker"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </FormControl>

              <FormControl fullWidth sx={{ marginBottom: 3 }}>
                <InputLabel shrink htmlFor="end-date-picker">
                  End Date
                </InputLabel>
                <DatePicker
                  name="End_Date"
                  value={values.End_Date ? dayjs(values.End_Date) : null} // Ensure it's a valid dayjs object or null
                  error={false}
                  onChange={(newValue) =>
                    setValues({ ...values, End_Date: newValue })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      id="end-date-picker"
                      sx={{ width: "100%" }}
                    />
                  )}
                />
              </FormControl>
            </LocalizationProvider>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
