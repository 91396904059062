import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import dayjs from "dayjs";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";

const proxy = process.env.REACT_APP_PROXY;

export default function TaskForm(props) {
  const { recordForEditTask, showNotification, onSubmit, selectedInitiative } =
    props;
  const [initiative, setInitiative] = useState([]);
  const [loading, setLoading] = useState(false);

  const initialFValues = {
    task_id: 0,
    initiative_id: selectedInitiative.Initiative_ID
      ? selectedInitiative.Initiative_ID
      : "",
    task: "",
    description: "",
    start_date: null,
    end_date: null,
    cost: 0,
    status: "",
  };

  useEffect(() => {
    const fetchInitiatives = async () => {
      try {
        const response = await axios.get(
          `${proxy}/initiatives/getAllInitiative`
        );
        setInitiative(response.data.data);
      } catch (error) {}
    };
    fetchInitiatives();
  }, []);

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEditTask || initialFValues,
    true
  );

  useEffect(() => {
    if (recordForEditTask) {
      console.log(recordForEditTask);
      setValues((prevValues) => ({
        ...prevValues,
        ...recordForEditTask,
        task_id: recordForEditTask.task_id || "", // Handle potential missing Initiative_ID
        start_date: recordForEditTask.start_date
          ? dayjs(recordForEditTask.start_date)
          : null,
        end_date: recordForEditTask.end_date
          ? dayjs(recordForEditTask.end_date)
          : null,
      }));
    } else {
      setValues(initialFValues);
    }
  }, [recordForEditTask, setValues, selectedInitiative]);

  const handleCostChange = (event) => {
    const inputValue = event.target.value;

    // Allow only numbers and a single decimal point
    const sanitizedValue = inputValue
      .replace(/[^0-9.]/g, "") // Remove non-numeric characters except decimal point
      .replace(/(\..*)\./g, "$1"); // Allow only one decimal point

    // Convert to a number (or 0 if empty)
    const numericValue = sanitizedValue === "" ? 0 : parseFloat(sanitizedValue);

    // Update the state with the numeric value
    setValues({ ...values, cost: numericValue });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const TaskData = {
      task_id: recordForEditTask ? recordForEditTask.task_id : null,
      initiative_id: values.initiative_id, // Use the value directly
      task: values.task,
      description: values.description,
      start_date: values.start_date
        ? values.start_date.format("YYYY-MM-DD")
        : "",
      end_date: values.end_date ? values.end_date.format("YYYY-MM-DD") : "",
      status: values.status,
      cost: values.cost,
    };

    try {
      if (recordForEditTask) {
        console.log(recordForEditTask.task_id);
        const response = await axios.put(
          `${proxy}/tasks/updateTask/${recordForEditTask.task_id}`,
          TaskData
        );
        console.log(response);
      } else {
        const response = await axios.post(`${proxy}/tasks/saveTask`, TaskData);
        console.log(response);
      }

      const { initiative_id } = TaskData;
      const costResponse = await axios.get(
        `${proxy}/tasks/getTasksCostByInitiative/${initiative_id}`
      );
      console.log("costResponse", costResponse);
      const totalCost = costResponse.data.data.totalCost || 0; // Handle potential null/undefined
      console.log("initiative_id", initiative_id);
      // 2. Update the initiative with the new total cost
      await axios.put(
        `${proxy}/initiatives/updateInitiativeCost/${initiative_id}`,
        { cost: totalCost }
      );

      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEditTask
          ? "Task updated successfully!"
          : "Task submitted successfully!",
        type: "success",
      });
      props.setOpenPopupTask(false);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to submit/update Task data or update initiative cost.",
        type: "error",
      });
      console.error("Error in handleSubmit:", error); // Log the full error for debugging
    } finally {
      setLoading(false);
    }
  };
  const handleReset = () => {
    resetForm();
    if (selectedInitiative) {
      // Only set initiative_id if selectedInitiative exists
      setValues({
        ...initialFValues,
        initiative_id: selectedInitiative.Initiative_ID,
      });
    } else {
      setValues(initialFValues);
    }
  };

  const handlePercentageChange = (event) => {
    const inputValue = event.target.value;

    // 1. Allow only numbers and a single decimal point
    const sanitizedValue = inputValue
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");

    // 2. Limit to 0-100 (or your desired range)
    const numericValue = parseFloat(sanitizedValue);
    const clampedValue = Math.max(
      0,
      Math.min(100, isNaN(numericValue) ? 0 : numericValue)
    ); //Clamping the number between 0-100

    setValues({ ...values, percentage: clampedValue }); // Update form values with the numeric value
  };
  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Initiative</InputLabel>
              <Select
                disabled
                placeholder="Initiative"
                name="Initiative"
                value={values.initiative_id}
                onChange={(e) =>
                  setValues({ ...values, initiative_id: e.target.value })
                }
                sx={{ minWidth: 500 }}
                margin="normal"
              >
                {initiative.map((initiative) => (
                  <MenuItem
                    key={initiative.Initiative_ID}
                    value={initiative.Initiative_ID}
                  >
                    {initiative.Initiative_Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <Controls.Input
              required
              name="task"
              placeholder="Task Name"
              label="Task Name"
              value={values.task}
              onChange={handleInputChange}
              error={errors.task}
              fullWidth
              autoFocus
              margin="normal"
            />
            <Controls.Input
              required
              placeholder="Description"
              name="description"
              label="Description"
              value={values.description}
              onChange={handleInputChange}
              error={errors.description}
              fullWidth
              autoFocus
              margin="normal"
              type="string"
              sx={{
                height: 200, // Set the outer height
                "& .MuiInputBase-root": { height: "100%" }, // Ensure inner input spans full height
              }}
              multiline
              rows={8}
            />
            <Controls.Input
              required
              type="number"
              name="cost"
              placeholder="Cost"
              label="Cost"
              value={values.cost} // Ensure this is a number
              onChange={handleCostChange} // Use the new handler for cost
              error={errors.cost}
              fullWidth
              margin="normal"
            />

            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="start_date"
                  slotProps={{
                    textField: {
                      helperText: "Start Date",
                    },
                  }}
                  value={values.start_date ? dayjs(values.start_date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, start_date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="end_date"
                  slotProps={{
                    textField: {
                      helperText: "End Date",
                    },
                  }}
                  value={values.end_date ? dayjs(values.end_date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, end_date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
            </div>

            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Status</InputLabel>
              <Select
                placeholder="Status"
                name="Status"
                value={values.status || ""} // Bind to `values.Status`
                onChange={
                  (e) => setValues({ ...values, status: e.target.value }) // Update status in values
                }
              >
                <MenuItem value="In progress">In progress</MenuItem>
                <MenuItem value="Completed">Completed</MenuItem>
                <MenuItem value="Planned">Planned</MenuItem>
                <MenuItem value="Postponed">Postponed</MenuItem>
              </Select>
            </FormControl>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
