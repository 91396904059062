import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import UseTable from "../../components/assests/UseTable";
import InitiativesForm from "./InitiativesForm";
import AppTheme from "../../Theme/AppTheme";
import Notification from "../../components/assests/Notification";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import axios from "axios";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import TaskForm from "./TaskForm";
import Tooltip from "@mui/material/Tooltip"; // Import Tooltip

const proxy = process.env.REACT_APP_PROXY;

const headCells = [
  { id: "task_id", label: "ID" },
  { id: "task", label: "Task" },
  { id: "description", label: "Description" },
  { id: "start_date", label: "Start Date" },
  { id: "end_date", label: "End Date" },
  { id: "cost", label: "Cost" },
  { id: "status", label: "Status" },
  { id: "actions", label: "Actions", disableSorting: true },
];

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth",
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 230 ? 230 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
  height: "100vh", // Set height to 100vh
  overflow: "hidden", // Prevent scrolling
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1, // Allow it to grow
  marginRight: theme.spacing(1),
  minWidth: 400, // Prevent overflow when the container is small
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

function InitiativesTasks({ drawerWidth, ...props }) {
  const [initativeRecords, setinItativeRecords] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [openPopupTask, setOpenPopupTask] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [recordForEditTask, setRecordForEditTask] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("InitiativeId");
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [initiativeIdToDelete, setInitiativeIdToDelete] = useState(null);
  const [taskIdToDelete, setTaskIdToDelete] = useState(null);
  const [reloadInitiatives, setReloadInitiatives] = useState(false);
  const [reloadTasks, setReloadTasks] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [searchInputTask, setSearchInputTask] = useState("");
  const [DialogName, setDialogName] = useState("");
  const [filteredTasks, setFilteredTasks] = useState([]);

  const [selectedInitiative, setSelectedInitiative] = useState(null);
  const [initiativeDetails, setInitiativeDetails] = useState(null);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { TblContainer } = UseTable(initativeRecords, headCells);

  const showNotification = (notification) => {
    setNotify(notification);
  };

  // fetch Objectives
  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  // fetch Departments
  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${proxy}/department/getDep`);
        setDepartments(response.data);
      } catch (error) {}
    };
    fetchDepartments();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const fetchInitiatives = async () => {
    try {
      const response = await axios.get(`${proxy}/initiatives/getAllInitiative`);
      setinItativeRecords(response.data.data);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to fetch plans",
        type: "error",
      });
    }
  };

  const sortedRecords = [...initativeRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const getDepartmentName = (departmentId) => {
    const department = departments.find(
      (dep) => dep.Department_ID === departmentId
    );
    return department ? department.Department_Name : "Unknown";
  };

  const sortedRecordsTasks = [...filteredTasks].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const filteredRecordsTasks = useMemo(() => {
    return sortedRecordsTasks.filter((task) => {
      return (
        (task.task &&
          task.task.toLowerCase().includes(searchInputTask.toLowerCase())) ||
        (task.description &&
          task.description
            .toLowerCase()
            .includes(searchInputTask.toLowerCase())) ||
        (task.status &&
          task.status.toLowerCase().includes(searchInputTask.toLowerCase()))
      );
    });
  }, [sortedRecordsTasks, searchInputTask]);

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((initiative) => {
      return (
        (initiative.Initiative_Name &&
          initiative.Initiative_Name.toLowerCase().includes(
            searchInput.toLowerCase()
          )) ||
        (initiative.Department_ID &&
          getDepartmentName(initiative.Department_ID)
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (initiative.Status &&
          initiative.Status.toLowerCase().includes(searchInput.toLowerCase()))
      );
    });
  }, [sortedRecords, searchInput]);

  useEffect(() => {
    fetchInitiatives();
  }, [reloadInitiatives]);

  useEffect(() => {
    fetchAndFilterTasks(selectedInitiative);
    handleInitiativeClick(selectedInitiative);
  }, [reloadTasks]);

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (initiativeIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/initiatives/deleteInitiative/${initiativeIdToDelete}`
        );
        if (response && response.data) {
          setReloadInitiatives((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete plan",
          type: "error",
        });
      } finally {
        setInitiativeIdToDelete(null); // Reset after delete
      }
    }
  };

  const handleConfirmDeleteTask = async () => {
    setOpenConfirmDialog(false);
    if (taskIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/tasks/deleteTask/${taskIdToDelete}`
        );
        if (response && response.data) {
          setReloadTasks((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete task",
          type: "error",
        });
      } finally {
        setInitiativeIdToDelete(null); // Reset after delete
      }
    }
  };

  const confirmDeleteObjective = (initiative_id) => {
    setInitiativeIdToDelete(initiative_id);
    setOpenConfirmDialog(true);
  };

  const confirmDeleteTask = (Initiative_ID) => {
    setTaskIdToDelete(Initiative_ID);
    setOpenConfirmDialog(true);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopup(true);
  };

  const handleAddTaskClick = () => {
    setRecordForEditTask(null);
    setOpenPopupTask(true);
  };

  const openInPopupTask = (item) => {
    setRecordForEditTask(item);
    setOpenPopupTask(true);
  };
  useEffect(() => {
    if (initativeRecords.length > 0) {
      const firstInitiative = initativeRecords[0];
      setSelectedInitiative(firstInitiative);
      setInitiativeDetails(firstInitiative);

      fetchAndFilterTasks(firstInitiative.Initiative_ID).then((filteredTasks) =>
        setFilteredTasks(filteredTasks)
      );
    }
  }, [initativeRecords]);

  const fetchAndFilterTasks = async (initiativeId) => {
    try {
      const tasksResponse = await axios.get(`${proxy}/tasks/getAllTasks`);
      const allTasks = tasksResponse.data.data;

      const filteredTasks = allTasks.filter(
        (task) => task.initiative_id === initiativeId
      );

      return filteredTasks; // Return the filtered tasks
    } catch (tasksError) {
      console.error("Error fetching tasks:", tasksError);
      showNotification({
        isOpen: true,
        message: "Failed to fetch tasks",
        type: "error",
      });
      return []; // Return an empty array in case of error
    }
  };

  const handleInitiativeClick = async (initiative) => {
    setSelectedInitiative(initiative);
    setInitiativeDetails(null);
    setFilteredTasks([]); // Clear existing tasks before fetching new ones

    try {
      const initiativesResponse = await axios.get(
        `${proxy}/initiatives/getAllInitiative`
      );
      const allInitiatives = initiativesResponse.data.data;

      const selectedDetails = allInitiatives.find(
        (item) => item.Initiative_ID === initiative.Initiative_ID
      );

      if (selectedDetails) {
        setInitiativeDetails(selectedDetails);

        const filteredTasks = await fetchAndFilterTasks(
          initiative.Initiative_ID
        );
        setFilteredTasks(filteredTasks);
      } else {
        showNotification({
          isOpen: true,
          message: "Initiative details not found!",
          type: "error",
        });
      }
    } catch (error) {
      // Handle error if needed
      console.error("Error in handleInitiativeClick:", error);
    }
  };
  return (
    <AppTheme {...props}>
      <ContentContainer
        drawerWidth={drawerWidth}
        sx={{ display: "flex", flexDirection: "column" }}
      >
        <PageHeader
          title="Initiative Page"
          subTitle="Define the Initiatives and Tasks"
          icon={<DownloadDoneIcon fontSize="large" />}
          sx={{ m: 0, p: 0 }}
        />
        <Box
          sx={{
            flexGrow: 1,
            mt: 0,
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            scrollbarWidth: "none", // Firefox
            msOverflowStyle: "none", // IE and Edge
          }}
        >
          <Grid
            container
            spacing={1}
            display="flex"
            flexGrow="1"
            flexDirection="row"
            width="100%"
            flexWrap="nowrap"
            gap="1"
            sx={{
              mt: 0,
              height: "100vh",
              maxHeight: "100vh",
              minHeight: "100%",
              overflowY: "auto",
            }}
          >
            <Box
              sx={{
                minHeight: "99%", // Minimum height
                maxHeight: "100%", // Maximum height
                overflowY: "auto",
                borderRight: "1px solid #ddd",
                p: 1,
                width: "100%",
                minWidth: "35%",
                maxWidth: "40%",
                display: "flex",
                flexDirection: "column",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                scrollbarWidth: "none", // Firefox
                msOverflowStyle: "none", // IE and Edge
              }}
            >
              <Typography variant="h4" gutterBottom>
                Initiatives
              </Typography>

              <Grid
                container
                spacing={1}
                sx={{
                  display: "flex",
                  flexDirection: "column", // This is the KEY change!
                  width: "100%",
                }}
              >
                <Toolbar
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                    width: "100%",
                  }}
                >
                  <SearchInput sx={{ m: 0, minWidth: "200px", p: 0 }}>
                    <Controls.Input
                      id="search-initiative-input"
                      placeholder="Search Initiatives"
                      value={searchInput}
                      sx={{ minWidth: "200px", p: 0 }}
                      onChange={(e) => setSearchInput(e.target.value)}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <Controls.ActionButton
                              color="secondary"
                              onClick={() => setSearchInput("")}
                            >
                              <CloseIcon fontSize="small" />
                            </Controls.ActionButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </SearchInput>

                  <NewButton sx={{ minWidth: "200px", p: 0 }}>
                    <Controls.Button
                      sx={{ minWidth: "200px" }}
                      text="Add Initiative"
                      variant="outlined"
                      startIcon={<AddIcon />}
                      onClick={() => {
                        setRecordForEdit(null);
                        setOpenPopup(true);
                      }}
                    />
                  </NewButton>
                </Toolbar>

                {filteredRecords.map((initiative) => (
                  <Card
                    key={initiative.Initiative_ID}
                    variant="outlined"
                    sx={{
                      width: "100%",
                      maxHeight: 150,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      boxSizing: "border-box",
                      cursor: "pointer",
                      m: 1,
                      mt: 0,
                    }}
                    onClick={() => handleInitiativeClick(initiative)}
                  >
                    <CardContent
                      sx={{ flexGrow: 1, width: "100%", overflow: "hidden" }}
                    >
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          fontWeight: "normal",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          maxWidth: "calc(100% - 1px)",
                        }}
                      >
                        {initiative.Initiative_Name || "Unnamed Initiative"}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary", mt: 1 }}
                      >
                        Status: {initiative.Status || "Unknown"}
                      </Typography>
                    </CardContent>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        pr: 1,
                        justifyContent: "flex-end",
                        minWidth: "120px", // Ensure buttons have enough space
                      }}
                    >
                      <Controls.ActionButton
                        onClick={(event) => {
                          event.stopPropagation();
                          openInPopup(initiative);
                        }}
                      >
                        <Tooltip title={"Edit"} arrow placement="left">
                          <EditOutlinedIcon fontSize="small" />
                        </Tooltip>
                      </Controls.ActionButton>
                      <Controls.ActionButton
                        color="error"
                        onClick={(event) => {
                          event.stopPropagation();
                          confirmDeleteObjective(initiative.Initiative_ID);
                          setDialogName("Initiative");
                        }}
                      >
                        <Tooltip title={"Delete"} arrow placement="right">
                          <CloseIcon fontSize="small" sx={{ color: "red" }} />
                        </Tooltip>
                      </Controls.ActionButton>
                    </Box>
                  </Card>
                ))}
              </Grid>
            </Box>

            <Grid
              container
              spacing={1}
              display="flex"
              flexGrow="1"
              flexDirection="column"
              width="100%"
              flexWrap="nowrap"
              gap="1"
              sx={{ ml: 1, p: 1, height: "100%" }}
              maxWidth="60%"
              minWidth="60%"
            >
              <Box
                sx={{
                  maxHeight: "100%",
                  overflowY: "auto",
                  borderBottom: "1px solid #ddd",
                  p: 1,
                  display: "flex",
                  flexDirection: "column",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none", // Firefox
                  msOverflowStyle: "none", // IE and Edge
                  minHeight: "40%",
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Initiatives Details
                </Typography>

                <Grid
                  container
                  spacing={2}
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "stretch", // Stretch cards vertically
                    mb: 1,
                    minHeight: "100%",
                  }}
                >
                  <Grid
                    item
                    xs={12} // Ensure the Grid item takes full width
                    key={
                      selectedInitiative
                        ? selectedInitiative.Initiative_ID
                        : "no-selection"
                    }
                    sx={{ width: "100%" }}
                  >
                    <Card
                      variant="outlined"
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        flexGrow: 1,
                      }}
                    >
                      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
                        {initiativeDetails && (
                          <>
                            <Typography
                              variant="h6"
                              component="div"
                              sx={{
                                fontWeight: "normal",
                                wordWrap: "break-word",
                              }}
                            >
                              Initiative Name:{" "}
                              {initiativeDetails.Initiative_Name}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary", mt: 1 }}
                            >
                              Description: {initiativeDetails.Description}
                            </Typography>
                            {initiativeDetails.Department_IDs && ( // Check if Department_IDs exist
                              <Typography
                                variant="body2"
                                sx={{ color: "text.secondary", mt: 1 }}
                              >
                                Departments:
                                {initiativeDetails.Department_IDs.split(
                                  ","
                                ).map((id) => {
                                  const departmentName = getDepartmentName(
                                    parseInt(id, 10)
                                  ); // Get name
                                  return (
                                    <span key={id}>
                                      {departmentName}
                                      {initiativeDetails.Department_IDs.split(
                                        ","
                                      ).indexOf(id) <
                                      initiativeDetails.Department_IDs.split(
                                        ","
                                      ).length -
                                        1
                                        ? ", "
                                        : ""}
                                    </span>
                                  );
                                })}
                              </Typography>
                            )}
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary", mt: 1 }}
                            >
                              Start Date:{" "}
                              {formatDate(initiativeDetails.Start_Date)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary", mt: 1 }}
                            >
                              End Date: {formatDate(initiativeDetails.End_Date)}
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary", mt: 1 }}
                            >
                              Budget: {initiativeDetails.budget} $
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary", mt: 1 }}
                            >
                              Cost: {initiativeDetails.cost} $
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{ color: "text.secondary", mt: 1 }}
                            >
                              Status: {initiativeDetails.Status}
                            </Typography>
                          </>
                        )}
                        {!initiativeDetails && selectedInitiative && (
                          <Typography>Loading details...</Typography>
                        )}
                        {!initiativeDetails && !selectedInitiative && (
                          <Typography>
                            Select an initiative to view details.
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Box>
              <Box
                sx={{
                  maxHeight: "100%",
                  overflowY: "auto",
                  p: 1,
                  width: "100%", // Box takes full width
                  display: "flex",
                  flexDirection: "column",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                  scrollbarWidth: "none", // Firefox
                  msOverflowStyle: "none", // IE and Edge
                }}
              >
                <Typography variant="h4" gutterBottom>
                  Milestones
                </Typography>
                <Grid item xs={12} sx={{ width: "100%", maxHeight: "45%" }}>
                  <Toolbar
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%", // Toolbar takes full width of its Grid item
                    }}
                  >
                    <SearchInput sx={{ flex: 1, mr: 0, minWidth: "500px" }}>
                      <Controls.Input
                        sx={{ minWidth: "100%" }}
                        id="search-task-input"
                        placeholder="Search Tasks"
                        value={searchInputTask}
                        onChange={(e) => setSearchInputTask(e.target.value)}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Controls.ActionButton
                                color="secondary"
                                onClick={() => setSearchInputTask("")}
                              >
                                <CloseIcon fontSize="small" />
                              </Controls.ActionButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </SearchInput>

                    <NewButton sx={{ minWidth: "auto" }}>
                      <Controls.Button
                        text="Add New Task"
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddTaskClick}
                      />
                    </NewButton>
                  </Toolbar>
                  <TblContainer>
                    <TableHead>
                      <TableRow>
                        {headCells.map((headCell) => (
                          <TableCell
                            sx={{ bgcolor: "#f4f6fa" }}
                            key={headCell.id}
                            sortDirection={
                              orderBy === headCell.id ? order : false
                            }
                          >
                            {headCell.disableSorting ? (
                              headCell.label
                            ) : (
                              <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={
                                  orderBy === headCell.id ? order : "asc"
                                }
                                onClick={(event) =>
                                  handleRequestSort(event, headCell.id)
                                }
                              >
                                {headCell.label}
                              </TableSortLabel>
                            )}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredRecordsTasks.map((item, index) => {
                        const serialNumber = index + 1;
                        return (
                          <TableRow
                            hover
                            key={item.task_id}
                            sx={{
                              backgroundColor: "white", // Add a background color
                              borderWidth: 2, // reduce the border width. 10 is too much.
                              borderColor: "black",
                            }}
                          >
                            <TableCell>{serialNumber}</TableCell>
                            <TableCell>{item.task}</TableCell>
                            <TableCell>{item.description}</TableCell>
                            <TableCell>{formatDate(item.start_date)}</TableCell>
                            <TableCell>{formatDate(item.end_date)}</TableCell>
                            <TableCell>{item.cost}</TableCell>
                            <TableCell>{item.status}</TableCell>
                            <TableCell>
                              <Controls.ActionButton
                                onClick={() => openInPopupTask(item)}
                              >
                                <Tooltip title={"Edit"} arrow placement="left">
                                  <EditOutlinedIcon fontSize="small" />
                                </Tooltip>
                              </Controls.ActionButton>
                              <Controls.ActionButton
                                color="error"
                                onClick={() => {
                                  setDialogName("Task"); // Set the dialog name FIRST
                                  confirmDeleteTask(item.task_id); // Then call the delete function
                                }}
                              >
                                <Tooltip
                                  title={"Delete"}
                                  arrow
                                  placement="right"
                                >
                                  <CloseIcon
                                    fontSize="small"
                                    sx={{ color: "red" }}
                                  />
                                </Tooltip>
                              </Controls.ActionButton>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </TblContainer>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </ContentContainer>
      <Popup
        title="Initiative Form"
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <InitiativesForm
          recordForEdit={recordForEdit}
          showNotification={showNotification}
          onSubmit={() => setReloadInitiatives((prev) => !prev)}
          setOpenPopup={setOpenPopup}
        />
      </Popup>
      <Popup
        title="Tasks Form"
        openPopup={openPopupTask}
        setOpenPopup={setOpenPopupTask}
      >
        {openPopupTask && (
          <TaskForm
            key={
              recordForEditTask
                ? recordForEditTask.task_id || "newTask"
                : "newTask"
            }
            recordForEditTask={recordForEditTask}
            showNotification={showNotification}
            onSubmit={() => {
              setReloadTasks((prev) => !prev);
              setOpenPopupTask(false);
              setRecordForEditTask(null);
            }}
            setOpenPopupTask={setOpenPopupTask}
            selectedInitiative={selectedInitiative} // Pass selectedInitiative conditionally
          />
        )}
      </Popup>

      <ConfirmationDialog
        open={openConfirmDialog}
        setOpen={setOpenConfirmDialog}
        DialogName={`Delete ${DialogName} Confirmation`} // Use template literal
        DialogText={`Are you sure you want to delete this ${DialogName}?`} // Use template literal
        onConfirm={() => {
          DialogName === "Task"
            ? handleConfirmDeleteTask()
            : handleConfirmDelete();
          showNotification({
            isOpen: true,
            message: `${DialogName} deleted successfully`, // Use template literal
            type: "success",
          });
          setOpenConfirmDialog(false); // Close the dialog after confirmation
        }}
        onClose={() => setOpenConfirmDialog(false)}
      />
      <Notification notify={notify} setNotify={setNotify} />
    </AppTheme>
  );
}

export default InitiativesTasks;
