import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

const proxy = process.env.REACT_APP_PROXY;

dayjs.extend(utc); // Extend with UTC
dayjs.extend(isSameOrBefore); // Extend dayjs with the isSameOrBefore plugin - DO THIS ONLY ONCE, AT THE TOP LEVEL

export default function KPIForm(props) {
  const { recordForEdit, onSubmit, showNotification } = props;
  const [loading, setLoading] = useState(false);
  const [objectives, setObjectives] = useState([]);
  const [actualValueInputs, setActualValueInputs] = useState([]);
  const [calculatedSummary, setCalculatedSummary] = useState(0);
  const [inputType, setInputType] = useState("number"); // State for input type
  const [initialActualValues, setInitialActualValues] = useState([]);
  const [kpiDataLoaded, setKpiDataLoaded] = useState(false); // New state to track if KPI data is loaded

  const KPIFValues = {
    KPI_Id: 0,
    KPI_Name: "",
    Target_Value: "",
    Actual_Value: "",
    Objective_Id: "",
    frequency: "",
    Measurement_unit: "",
    Direction: "",
    Functionality: "",
    Start_Date: null,
    End_Date: null,
  };
  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEdit || KPIFValues,
    true
  );

  const getInputType = (measurementUnit) => {
    switch (measurementUnit) {
      case "Percentage":
        return "number";
      case "Number":
        return "number";
      case "Text":
        return "text";
      default:
        return "number"; // Default to number
    }
  };

  useEffect(() => {
    if (values.Measurement_unit === "Text") {
      // Set default target value to 1 when measurement unit is "Text"
      setValues({ ...values, Target_Value: "1" });
    }
  }, [values.Measurement_unit, setValues, values]);

  useEffect(() => {
    if (kpiDataLoaded) {
      if (
        initialActualValues.length > 0 &&
        values.Start_Date &&
        values.End_Date &&
        values.frequency
      ) {
        const calculatedValues = calculateActualValues(
          values.Start_Date,
          values.End_Date,
          values.frequency
        );

        const updatedCalculatedValues = calculatedValues.map(
          (calculatedValue) => {
            const matchingInitialValue = initialActualValues.find(
              (initialValue) => {
                return dayjs(initialValue.Date).isSame(
                  calculatedValue.date,
                  "day"
                ); // Compare by day
              }
            );

            return {
              ...calculatedValue,
              value: matchingInitialValue
                ? matchingInitialValue.Actual_Value
                : calculatedValue.value,
            };
          }
        );

        setActualValueInputs(updatedCalculatedValues);
        console.log("Updated Values:", updatedCalculatedValues);
      } else if (values.Start_Date && values.End_Date && values.frequency) {
        const calculatedValues = calculateActualValues(
          values.Start_Date,
          values.End_Date,
          values.frequency
        );
        setActualValueInputs(calculatedValues);
        console.log("Calculated Values:", calculatedValues);
      }
    }
  }, [
    initialActualValues,
    values.Start_Date,
    values.End_Date,
    values.frequency,
    kpiDataLoaded,
  ]);

  useEffect(() => {
    // Calculate summary whenever actualValueInputs or functionality changes
    calculateSummary();
  }, [actualValueInputs, values.Functionality]);

  const calculateSummary = () => {
    if (!actualValueInputs || actualValueInputs.length === 0) {
      setCalculatedSummary(0);
      return;
    }

    let summary = 0;
    switch (values.Functionality) {
      case "Cumulative":
        summary = actualValueInputs.reduce(
          (sum, item) => sum + parseFloat(item.value || 0),
          0
        );
        break;
      case "Average of All Entries":
        summary =
          actualValueInputs.reduce(
            (sum, item) => sum + parseFloat(item.value || 0),
            0
          ) / actualValueInputs.length; // Parse to float
        break;
      case "Last":
        summary = parseFloat(
          actualValueInputs[actualValueInputs.length - 1].value || 0
        ); // Parse to float
        break;
      default:
        summary = 0; // Or handle unknown functionality
    }
    setCalculatedSummary(summary);
  };

  const calculateActualValues = (
    startDate,
    endDate,
    frequency,
    initialValue
  ) => {
    if (!startDate || !endDate || !frequency) {
      return [];
    }

    let start = dayjs(startDate).startOf("day"); // Truncate time component
    let end = dayjs(endDate).startOf("day"); // Truncate time component

    let periods = [];
    let currentDate = start;

    while (currentDate.isSameOrBefore(end)) {
      periods.push(currentDate.format("YYYY-MM-DD"));
      switch (frequency) {
        case "Annually":
          currentDate = currentDate.add(1, "year");
          break;
        case "Semi Annually":
          currentDate = currentDate.add(6, "month");
          break;
        case "Quarterly":
          currentDate = currentDate.add(3, "month");
          break;
        case "Monthly":
          currentDate = currentDate.add(1, "month");
          break;
        default:
          return [];
      }
    }

    return periods.map((date) => ({
      date: date,
      value: initialValue || 0,
    }));
  };

  const handleActualValueChange = (index, newValue) => {
    const updatedInputs = [...actualValueInputs];
    updatedInputs[index].value = newValue;
    setActualValueInputs(updatedInputs);
  };

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        setObjectives(response.data.data);
      } catch (error) {}
    };
    fetchObjectives();
  }, []);

  //start from here

  useEffect(() => {
    if (recordForEdit) {
      setKpiDataLoaded(false); // Reset the flag
      setValues({
        KPI_Id: recordForEdit.KPI_Id,
        KPI_Name: recordForEdit.KPI_Name,
        Actual_Value: recordForEdit.Actual_Value,
        Target_Value: recordForEdit.Target_Value,
        Objective_Id: recordForEdit.Objective_Id,
        Measurement_unit: recordForEdit.Measurement_unit,
        Direction: recordForEdit.Direction,
        frequency: recordForEdit.frequency,
        Functionality: recordForEdit.Functionality,
        Start_Date: recordForEdit.Start_Date
          ? dayjs(recordForEdit.Start_Date)
          : null,
        End_Date: recordForEdit.End_Date ? dayjs(recordForEdit.End_Date) : null,
      });

      const fetchInitialActualValues = async () => {
        try {
          const response = await axios.get(
            `${proxy}/kpiActualValue/getKPIActualValuesByKPI/${recordForEdit.KPI_Id}`
          );
          if (response.data && response.data.data) {
            setInitialActualValues(response.data.data);
          }
        } catch (error) {
          console.error("Error fetching initial actual values:", error);
        } finally {
          setKpiDataLoaded(true); // Set the flag in finally
        }
      };
      fetchInitialActualValues();
    } else {
      resetForm();
      setInitialActualValues([]);
      setActualValueInputs([]);
      setKpiDataLoaded(true); // Data is loaded immediately in create mode
    }
  }, [recordForEdit, setValues]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Escape") {
        props.setOpenPopup(false); // Close the form
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [props, props.setOpenPopup]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    try {
      let calculatedActualValue = 0;
      switch (values.Functionality) {
        case "Cumulative":
          calculatedActualValue = actualValueInputs.reduce(
            (sum, item) => sum + parseFloat(item.value || 0),
            0
          );
          break;
        case "Average of All Entries":
          calculatedActualValue =
            actualValueInputs.reduce(
              (sum, item) => sum + parseFloat(item.value || 0),
              0
            ) / actualValueInputs.length;
          break;
        case "Last":
          calculatedActualValue = parseFloat(
            actualValueInputs[actualValueInputs.length - 1].value || 0
          );
          break;
        default:
          calculatedActualValue = 0;
      }

      const KPIData = {
        KPI_Id: recordForEdit ? recordForEdit.KPI_Id : null,
        KPI_Name: values.KPI_Name,
        Actual_Value: calculatedActualValue,
        Target_Value: values.Target_Value,
        Objective_Id: values.Objective_Id,
        frequency: values.frequency,
        Measurement_unit: values.Measurement_unit,
        Direction: values.Direction,
        Functionality: values.Functionality,
        Start_Date: values.Start_Date
          ? dayjs(values.Start_Date).format("YYYY-MM-DD")
          : "",
        End_Date: values.End_Date
          ? dayjs(values.End_Date).format("YYYY-MM-DD")
          : "",
      };

      if (recordForEdit) {
        // 1. Update KPI
        await axios.put(
          `${proxy}/kpi/updateKPI/${recordForEdit.KPI_Id}`,
          KPIData
        );

        // 2. Update/Create KPI Actual Values
        for (const actualValue of actualValueInputs) {
          const existingActualValue = initialActualValues.find((initial) => {
            return (
              initial.KPI_Actual_Value_ID &&
              dayjs(initial.Date).isSame(actualValue.date, "day")
            );
          });

          if (existingActualValue) {
            // Update existing entry
            await axios.put(
              `${proxy}/kpiActualValue/updateKPIActualValue/${existingActualValue.KPI_Actual_Value_ID}`,
              {
                KPI_Id: recordForEdit.KPI_Id,
                Date: actualValue.date,
                Actual_Value: actualValue.value,
              }
            );
          } else {
            // Create a new entry
            await axios.post(`${proxy}/kpiActualValue/saveKPIActualValue`, {
              KPI_Id: recordForEdit.KPI_Id,
              Date: actualValue.date,
              Actual_Value: actualValue.value,
            });
          }
        }
      } else {
        // Create New KPI and Actual Values
        const kpiResponse = await axios.post(`${proxy}/kpi/saveKPI`, KPIData);
        const newKPIId = kpiResponse.data.data.KPI_Id;

        for (const actualValue of actualValueInputs) {
          await axios.post(`${proxy}/kpiActualValue/saveKPIActualValue`, {
            KPI_Id: newKPIId,
            Date: actualValue.date,
            Actual_Value: actualValue.value,
          });
        }
      }

      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEdit
          ? "KPI updated successfully!"
          : "KPI submitted successfully!",
        type: "success",
      });
      props.setOpenPopup(false);
    } catch (error) {
      if (error.response && error.response.status === 409) {
        showNotification({
          isOpen: true,
          message: "KPI already exists.",
          type: "warning",
        });
      } else {
        showNotification({
          isOpen: true,
          message: "Failed to submit KPI data. " + (error.message || error),
          type: "error",
        });
        console.error("Error submitting KPI:", error);
      }
    } finally {
      setLoading(false);
    }
  };

  const handlePercentageChange = (event) => {
    const inputValue = event.target.value;

    // 1. Allow only numbers and a single decimal point
    const sanitizedValue = inputValue
      .replace(/[^0-9.]/g, "")
      .replace(/(\..*)\./g, "$1");

    // 2. Limit to 0-100 (or your desired range)
    const numericValue = parseFloat(sanitizedValue);
    const clampedValue = Math.max(
      0,
      Math.min(100, isNaN(numericValue) ? 0 : numericValue)
    ); //Clamping the number between 0-100

    setValues({ ...values, percentage: clampedValue }); // Update form values with the numeric value
  };

  const handleReset = () => {
    resetForm();
    setInitialActualValues([]);
    setActualValueInputs([]);
  };

  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" margin="normal">
              <InputLabel>Objective</InputLabel>
              <Select
                placeholder="Objective"
                name="Objective_Id"
                value={values.Objective_Id || ""}
                onChange={(e) =>
                  setValues({ ...values, Objective_Id: e.target.value })
                }
                sx={{ minWidth: 900 }}
                margin="normal"
              >
                {objectives.map((objective) => (
                  <MenuItem
                    key={objective.Strategic_Objectives_ID}
                    value={objective.Strategic_Objectives_ID}
                  >
                    {objective.Description}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Controls.Input
              required
              name="KPI_Name"
              placeholder="KPI Name"
              value={values.KPI_Name}
              onChange={handleInputChange}
              error={errors.KPI_Name}
              fullWidth
              autoFocus
              margin="normal"
            />
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="Start_Date"
                  slotProps={{
                    textField: {
                      helperText: "Start Date",
                    },
                  }}
                  value={values.Start_Date ? dayjs(values.Start_Date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, Start_Date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  required
                  name="End_Date"
                  slotProps={{
                    textField: {
                      helperText: "End Date",
                    },
                  }}
                  value={values.End_Date ? dayjs(values.End_Date) : null}
                  onChange={(newValue) =>
                    setValues({ ...values, End_Date: newValue })
                  }
                  sx={{
                    width: "48%", // Set width to 48% of the parent container
                    "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                  }}
                />
              </LocalizationProvider>
            </div>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                marginTop: 16,
              }}
            >
              <FormControl
                fullWidth
                variant="filled"
                required
                margin="normal"
                sx={{
                  width: "24%", // Set width to 48% of the parent container
                  "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                }}
              >
                <InputLabel>Frequency</InputLabel>
                <Select
                  placeholder="Frequency"
                  name="frequency"
                  value={values.frequency || ""} // Bind to `values.Status`
                  onChange={
                    (e) => setValues({ ...values, frequency: e.target.value }) // Update status in values
                  }
                >
                  <MenuItem value="Annually">Annually</MenuItem>
                  <MenuItem value="Semi Annually">Semi Annually</MenuItem>
                  <MenuItem value="Quarterly">Quarterly</MenuItem>
                  <MenuItem value="Monthly">Monthly</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                required
                margin="normal"
                sx={{
                  width: "24%", // Set width to 48% of the parent container
                  "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                }}
              >
                <InputLabel>Measurement Unit</InputLabel>
                <Select
                  placeholder="Measurement Unit"
                  name="Measurement_unit"
                  value={values.Measurement_unit || ""} // Bind to `values.Status`
                  onChange={(e) => {
                    const measurementUnit = e.target.value;
                    setValues({ ...values, Measurement_unit: measurementUnit });
                    const newInputType = getInputType(measurementUnit); // Get the input type
                    setInputType(newInputType); // Set the input type state
                  }}
                >
                  <MenuItem value="Percentage">Percentage</MenuItem>
                  <MenuItem value="Number">Number</MenuItem>
                  <MenuItem value="Text">Text</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                variant="filled"
                required
                margin="normal"
                sx={{
                  width: "24%", // Set width to 48% of the parent container
                  "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                }}
              >
                <InputLabel>Indicator Direction</InputLabel>
                <Select
                  placeholder="Indicator Direction"
                  name="Direction"
                  value={values.Direction || ""} // Bind to `values.Status`
                  onChange={
                    (e) => setValues({ ...values, Direction: e.target.value }) // Update status in values
                  }
                >
                  <MenuItem value="High">High</MenuItem>
                  <MenuItem value="Low">Low</MenuItem>
                </Select>
              </FormControl>
              <FormControl
                fullWidth
                required
                variant="filled"
                margin="normal"
                sx={{
                  width: "24%", // Set width to 48% of the parent container
                  "& .MuiInputBase-root": { width: "100%" }, // Ensure input spans full width
                }}
              >
                <InputLabel>Functionality</InputLabel>
                <Select
                  placeholder="Functionality"
                  name="Functionality"
                  value={values.Functionality || ""} // Bind to `values.Status`
                  onChange={
                    (e) =>
                      setValues({ ...values, Functionality: e.target.value }) // Update status in values
                  }
                >
                  <MenuItem value="Cumulative">Cumulative</MenuItem>
                  <MenuItem value="Average">Average of All Entries</MenuItem>
                  <MenuItem value="Last">Last value</MenuItem>
                </Select>
              </FormControl>
            </div>
            <FormControl fullWidth margin="normal">
              {values.Measurement_unit === "Text" ? (
                <Controls.Input
                  label="Target Value"
                  value="Yes"
                  readOnly
                  fullWidth
                  margin="normal"
                />
              ) : (
                <Controls.Input
                  id="outlined-number"
                  required
                  label="Target Value"
                  name="Target_Value"
                  variant="outlined"
                  placeholder="Target Value"
                  value={values.Target_Value}
                  onChange={handleInputChange}
                  error={errors.Target_Value}
                  fullWidth
                  autoFocus
                  margin="normal"
                  type="number"
                />
              )}
            </FormControl>
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "space-between",
                flexDirection: "column",
                marginTop: 1,
              }}
            >
              {actualValueInputs.map((item, index) =>
                values.Measurement_unit === "Text" ? (
                  <FormControl key={index} fullWidth margin="normal">
                    <InputLabel>{`Actual Value (${item.date})`}</InputLabel>
                    <Select
                      value={item.value}
                      onChange={(event) => {
                        handleActualValueChange(index, event.target.value);
                      }}
                    >
                      <MenuItem value="1">Yes</MenuItem>
                      <MenuItem value="0">No</MenuItem>
                    </Select>
                  </FormControl>
                ) : (
                  <Controls.Input
                    key={index}
                    label={`Actual Value (${item.date})`}
                    name={`Actual_Value_${index}`}
                    placeholder="Actual Value"
                    type={inputType}
                    value={item.value}
                    onChange={(event) => {
                      handleActualValueChange(index, event.target.value);
                    }}
                    fullWidth
                    margin="normal"
                  />
                )
              )}
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
