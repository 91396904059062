import React, { useState, useEffect, useMemo } from "react";
import Controls from "../../components/assests/Controls";
import PageHeader from "../../components/assests/PageHeader";
import {
  TableBody,
  TableRow,
  TableCell,
  Toolbar,
  InputAdornment,
  TableHead,
  TableSortLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import AddIcon from "@mui/icons-material/Add";
import Popup from "../../components/assests/Popup";
import CloseIcon from "@mui/icons-material/Close";
import UseTable from "../../components/assests/UseTable";
import AppTheme from "../../Theme/AppTheme";
import StrategicForm from "./StrategicForm";
import axios from "axios";
import ConfirmationDialog from "../../components/assests/ConfirmationDialog";
import Notification from "../../components/assests/Notification";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";

const proxy = process.env.REACT_APP_PROXY;

// Styled components
const ContentContainer = styled("div", {
  shouldForwardProp: (prop) => prop !== "drawerWidth", // Exclude drawerWidth prop from being passed to DOM
})(({ theme, drawerWidth }) => ({
  marginLeft: drawerWidth === 240 ? 240 : 0,
  transition: "margin-left 0.3s ease",
  width: `calc(100% - ${drawerWidth}px)`,
}));

const SearchInput = styled("div")(({ theme }) => ({
  flex: 1,
  marginRight: theme.spacing(1),
  minWidth: 0,
}));

const NewButton = styled("div")(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const headCells = [
  { id: "Strategic_Plan_ID", label: "ID" },
  { id: "strategic_plan_Description", label: "Plan" },
  { id: "vision", label: "Vision" },
  { id: "mission", label: "Mission" },
  { id: "strategy_statement", label: "Strategy Statement" },
  { id: "Start_Date", label: "Start Date" },
  { id: "End_Date", label: "End Date" },
  { id: "actions", label: "Actions", disableSorting: true },
];

export default function StrategicPlan({ drawerWidth, ...props }) {
  const [plansRecords, setPlansRecords] = useState([]);
  const [openPopupPlan, setOpenPopupPlan] = useState(false);
  const [recordForEdit, setRecordForEdit] = useState(null);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("Strategic_Plan_ID");
  const [reloadPlan, setReloadPlan] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [planIdToDelete, setPlanIdToDelete] = useState(null);
  const [searchInput, setSearchInput] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const { TblContainer } = UseTable(plansRecords, headCells);

  const fetchPlans = async () => {
    try {
      const response = await axios.get(`${proxy}/plan/getAllPlans`);
      setPlansRecords(response.data.data);
    } catch (error) {
      showNotification({
        isOpen: true,
        message: "Failed to fetch plans",
        type: "error",
      });
    }
  };

  const showNotification = (notification) => {
    setNotify(notification);
  };

  useEffect(() => {
    fetchPlans();
  }, [reloadPlan]);

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === "asc";
    const newOrder = isAscending ? "desc" : "asc";
    setOrder(newOrder);
    setOrderBy(property);
  };
  const formatDate = (dateString) => {
    if (!dateString) return "";
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  };

  const sortedRecords = [...plansRecords].sort((a, b) => {
    if (a[orderBy] < b[orderBy]) {
      return order === "asc" ? -1 : 1;
    }
    if (a[orderBy] > b[orderBy]) {
      return order === "asc" ? 1 : -1;
    }
    return 0;
  });

  const openInPopup = (item) => {
    setRecordForEdit(item);
    setOpenPopupPlan(true);
  };

  const confirmDeleteUser = (Strategic_Plan_ID) => {
    setPlanIdToDelete(Strategic_Plan_ID);
    setOpenConfirmDialog(true);
  };

  const handleConfirmDelete = async () => {
    setOpenConfirmDialog(false);
    if (planIdToDelete) {
      try {
        const response = await axios.delete(
          `${proxy}/plan/deletePlan/${planIdToDelete}`
        );
        if (response && response.data) {
          setReloadPlan((prev) => !prev);
        }
      } catch (error) {
        showNotification({
          isOpen: true,
          message: "Failed to delete plan",
          type: "error",
        });
      } finally {
        setPlanIdToDelete(null); // Reset after delete
      }
    }
  };

  const filteredRecords = useMemo(() => {
    return sortedRecords.filter((plan) => {
      return (
        (plan.strategic_plan_Description &&
          plan.strategic_plan_Description
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (plan.vision &&
          plan.vision.toLowerCase().includes(searchInput.toLowerCase())) ||
        (plan.mission &&
          plan.mission.toLowerCase().includes(searchInput.toLowerCase())) ||
        (plan.strategy_statement &&
          plan.strategy_statement
            .toLowerCase()
            .includes(searchInput.toLowerCase())) ||
        (plan.Start_Date &&
          plan.Start_Date.toLowerCase().includes(searchInput.toLowerCase())) ||
        (plan.End_Date &&
          plan.End_Date.toLowerCase().includes(searchInput.toLowerCase()))
      );
    });
  }, [sortedRecords, searchInput]);

  return (
    <AppTheme {...props}>
      <ContentContainer drawerWidth={drawerWidth}>
        <PageHeader
          title="Startegic Plan Page"
          subTitle="Define Startegic Plans"
          icon={<ReceiptLongIcon fontSize="large" />}
        />
        <Toolbar sx={{ display: "flex", alignItems: "center" }}>
          <SearchInput>
            <Controls.Input
              sx={{ width: "100%" }}
              id="search-plan-data"
              placeholder="Search Plan"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => setSearchInput("")}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </InputAdornment>
                ),
              }}
            />
          </SearchInput>
          <NewButton>
            <Controls.Button
              text="Add New Plan"
              variant="outlined"
              startIcon={<AddIcon />}
              onClick={() => {
                setRecordForEdit(null);
                setOpenPopupPlan(true);
              }}
            />
          </NewButton>
        </Toolbar>

        <TblContainer>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell
                  // sx={{ bgcolor: "#f4f6fa" }}
                  key={headCell.id}
                  sortDirection={orderBy === headCell.id ? order : false}
                >
                  {headCell.disableSorting ? (
                    headCell.label
                  ) : (
                    <TableSortLabel
                      active={orderBy === headCell.id}
                      direction={orderBy === headCell.id ? order : "asc"}
                      onClick={(event) => handleRequestSort(event, headCell.id)}
                    >
                      {headCell.label}
                    </TableSortLabel>
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredRecords.map((item, index) => {
              const serialNumber = index + 1;

              return (
                <TableRow key={item.Strategic_Plan_ID}>
                  <TableCell>{serialNumber}</TableCell>
                  <TableCell>{item.strategic_plan_Description}</TableCell>
                  <TableCell>{item.vision}</TableCell>
                  <TableCell>{item.mission}</TableCell>
                  <TableCell>{item.strategy_statement}</TableCell>
                  <TableCell>{formatDate(item.Start_Date)}</TableCell>
                  <TableCell>{formatDate(item.End_Date)}</TableCell>
                  <TableCell>
                    <Controls.ActionButton
                      color="primary"
                      onClick={() => {
                        openInPopup(item);
                      }}
                    >
                      <EditOutlinedIcon fontSize="small" />
                    </Controls.ActionButton>
                    <Controls.ActionButton
                      color="secondary"
                      onClick={() => confirmDeleteUser(item.Strategic_Plan_ID)}
                    >
                      <CloseIcon fontSize="small" />
                    </Controls.ActionButton>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </TblContainer>
        <Popup
          title="Stategic Plan Form"
          openPopup={openPopupPlan}
          setOpenPopup={setOpenPopupPlan}
        >
          <StrategicForm
            recordForEdit={recordForEdit}
            onSubmit={() => setReloadPlan((prev) => !prev)}
            setOpenPopup={setOpenPopupPlan}
            showNotification={showNotification}
          />
        </Popup>

        <ConfirmationDialog
          open={openConfirmDialog}
          setOpen={setOpenConfirmDialog}
          DialogName="Delete Plan Confirmation"
          DialogText="Are you sure you want to delete this plan?"
          onConfirm={() => {
            handleConfirmDelete();
            showNotification({
              isOpen: true,
              message: "Plan deleted successfully",
              type: "success",
            });
          }}
          onClose={() => setOpenConfirmDialog(false)}
        />
        <Notification notify={notify} setNotify={setNotify} />
      </ContentContainer>
    </AppTheme>
  );
}
