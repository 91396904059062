import React, { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import axios from "axios";
import Select from "@mui/material/Select";
import Typography from "@mui/material/Typography";
import { Gauge, gaugeClasses } from "@mui/x-charts/Gauge";
import AppTheme from "../../Theme/AppTheme";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField"; // Import TextField for search

const proxy = process.env.REACT_APP_PROXY;

function KPI() {
  const [objectives, setObjectives] = useState([]);
  const [kpis, setKPIs] = useState([]);
  const [values, setValues] = useState({ Objective_Id: "" });
  const [searchText, setSearchText] = useState(""); // State for search text

  useEffect(() => {
    const fetchObjectives = async () => {
      try {
        const response = await axios.get(`${proxy}/objective/getAllObjectives`);
        const allObjectives = response.data.data;

        // Filter objectives to include only those with associated KPIs
        const objectivesWithKPIs = await Promise.all(
          allObjectives.map(async (objective) => {
            const kpiResponse = await axios.get(
              `${proxy}/kpi/getKPIsByObjective/${objective.Strategic_Objectives_ID}`
            );
            return kpiResponse.data.data.length > 0 ? objective : null;
          })
        );

        // Filter out null values and update state
        const filteredObjectives = objectivesWithKPIs.filter(Boolean);
        setObjectives(filteredObjectives);

        // Set default to the last objective if available
        if (filteredObjectives.length > 0) {
          setValues((prevValues) => ({
            ...prevValues,
            Objective_Id:
              filteredObjectives[filteredObjectives.length - 1]
                .Strategic_Objectives_ID,
          }));
        }
      } catch (error) {}
    };

    fetchObjectives();
  }, []);

  // Fetch KPIs when an objective is selected
  useEffect(() => {
    const fetchKPIsByObjective = async () => {
      if (values.Objective_Id) {
        try {
          const response = await axios.get(
            `${proxy}/kpi/getKPIsByObjective/${values.Objective_Id}`
          );
          setKPIs(response.data.data); // Store the KPIs fetched from the backend
        } catch (error) {}
      }
    };
    fetchKPIsByObjective();
  }, [values.Objective_Id]);

  // Filter the objectives based on the search text
  const filteredObjectives = objectives.filter((objective) =>
    objective.Description.toLowerCase().includes(searchText.toLowerCase())
  );

  // Select the first matching objective automatically
  useEffect(() => {
    if (searchText && filteredObjectives.length === 1) {
      setValues((prevValues) => ({
        ...prevValues,
        Objective_Id: filteredObjectives[0].Strategic_Objectives_ID,
      }));
    }
  }, [searchText, filteredObjectives]);

  return (
    <AppTheme>
      <CssBaseline enableColorScheme />
      <Box sx={{ width: "100%", flexGrow: 1, padding: 1 }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-around", // Ensures space between elements
            alignItems: "center", // Vertically align the elements
            gap: 2, // Adds spacing between the search input and dropdown
            marginBottom: 2, // Adds space below the search and dropdown section
          }}
        >
          <TextField
            label="Search"
            variant="outlined"
            sx={{ width: "45%", height: 40, fontSize: "1rem" }} // Makes the search field take up 45% width
            value={searchText}
            onChange={(e) => setSearchText(e.target.value)} // Update search text
          />

          <FormControl
            variant="filled"
            sx={{
              flex: 1,
              maxWidth: "45%", // Limits the width of the select dropdown
              height: 40,
            }}
          >
            <InputLabel
              sx={{
                fontSize: "1rem",
                top: "-4px", // Aligns label with input placeholder
              }}
            >
              Objective
            </InputLabel>
            <Select
              name="Objective_Id"
              value={values.Objective_Id || ""}
              onChange={(e) =>
                setValues({ ...values, Objective_Id: e.target.value })
              }
              sx={{
                height: 40, // Same height as input
                padding: "10px 12px",
              }}
            >
              {filteredObjectives.map((objective) => (
                <MenuItem
                  key={objective.Strategic_Objectives_ID}
                  value={objective.Strategic_Objectives_ID}
                >
                  {objective.Description}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>

        {values.Objective_Id && (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
              gap: 2, // Adds spacing between the KPI cards
            }}
          >
            {kpis.map((kpi) => (
              <Paper
                key={kpi.KPI_Id}
                elevation={3}
                sx={{
                  width: "25%",
                  minHeight: "250px",
                  padding: 2,
                  display: "flex",
                  justifyContent: "space-around",
                  flexDirection: "column",
                  alignItems: "center",
                  textAlign: "center",
                }}
              >
                <Gauge
                  width={200}
                  height={200}
                  value={
                    (kpi.Actual_Value / kpi.Target_Value > 1
                      ? kpi.Actual_Value / kpi.Target_Value - 1
                      : kpi.Actual_Value / kpi.Target_Value) * 100
                  }
                  startAngle={kpi.Actual_Value <= kpi.Target_Value ? -110 : 110}
                  endAngle={kpi.Actual_Value <= kpi.Target_Value ? 110 : -110}
                  sx={{
                    [`& .${gaugeClasses.valueText}`]: {
                      fontSize: 20,
                      transform: "translate(0px, 0px)",
                    },
                    [`& .${gaugeClasses.valueArc}`]: {
                      fill:
                        kpi.Actual_Value <= kpi.Target_Value
                          ? "#52b202"
                          : "#FF0000",
                    },
                  }}
                  text={({ value }) => `${Math.round(value)}%`}
                />
                <Typography
                  variant="body2"
                  sx={{
                    wordWrap: "break-word",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "normal",
                    maxWidth: "200px",
                  }}
                >
                  {kpi.KPI_Name}
                </Typography>
              </Paper>
            ))}
          </Box>
        )}
      </Box>
    </AppTheme>
  );
}

export default KPI;
