import * as React from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer, { drawerClasses } from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import MenuContent from "./MenuContent"; // Ensure this component exists
import OptionsMenu from "./OptionMenu"; // Ensure this component exists
import { useUser } from "../../utils/UserContext"; // Ensure this is correct path
import useMediaQuery from "@mui/material/useMediaQuery";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useDrawer } from "../../utils/DrawerProvider"; // Ensure correct context path
import logo from "../photos/logo.png";

const Drawer = styled(MuiDrawer)(({ theme, drawerWidth }) => ({
  width: drawerWidth,
  flexShrink: 0,
  boxSizing: "border-box",
  [`& .${drawerClasses.paper}`]: {
    width: drawerWidth,
    boxSizing: "border-box",
    background: "#262b40", // Gradient background
  },
}));

const SideMenu = () => {
  const { drawerWidth, toggleDrawer } = useDrawer(); // Get drawer width and toggle function from context
  const { user, loading } = useUser();
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [open, setOpen] = React.useState(true); // Open state controls the visibility of the drawer

  const handleToggleDrawer = () => {
    setOpen((prevOpen) => {
      const newOpenState = !prevOpen;
      toggleDrawer(); // This will toggle the drawerWidth state from context
      return newOpenState;
    });
  };

  // Get current date in YYYY-MM-DD format
  const currentDate = new Date().toLocaleDateString();

  return (
    <>
      {isSmallScreen && (
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleToggleDrawer}
          sx={{ ml: 2, mt: 2 }}
        >
          <MenuIcon />
        </IconButton>
      )}
      <Drawer
        variant={isSmallScreen ? "temporary" : "permanent"}
        open={isSmallScreen ? open : true}
        onClose={handleToggleDrawer}
        drawerWidth={drawerWidth}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            transition: "width 0.3s ease-in-out", // Smooth drawer transition
          },
        }}
      >
        <IconButton
          onClick={handleToggleDrawer}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 1500,
            color: "#FFFFFF", // White for the icon
            marginBottom: 10,
          }}
        >
          {drawerWidth === 230 ? (
            <ArrowCircleLeftIcon />
          ) : (
            <ArrowCircleRightIcon />
          )}
        </IconButton>
        <img
          src={logo}
          alt="Logo"
          style={{ marginTop: 30, marginBottom: 0, padding: 0 }}
        />
        <MenuContent drawerWidth={drawerWidth} />
        <Stack
          direction="row"
          sx={{
            p: 2,
            borderTop: "1px solid",
            justifyContent: "space-between", // Align avatar and content horizontally
            alignItems: "center",
          }}
        >
          {loading ? (
            <Typography>Loading...</Typography>
          ) : (
            <>
              {drawerWidth >= 230 && ( // Show name, email, and date when drawer width is 230 or more
                <Box sx={{ ml: 2, textAlign: "left" }}>
                  <Typography sx={{ color: "#FFFFFF" }}>
                    {user?.Name || user?.name || "Guest"}
                  </Typography>
                  <Typography
                    sx={{ color: "#FFFFFF", fontSize: "0.875rem", mt: 1 }}
                  >
                    {currentDate}
                  </Typography>
                </Box>
              )}
            </>
          )}
          {drawerWidth >= 230 && <OptionsMenu />}
        </Stack>
      </Drawer>
    </>
  );
};

export default SideMenu;
