// fileUpload.js
import axios from "axios";

const proxy = process.env.REACT_APP_PROXY;
export const uploadFile = async (file) => {
  if (!file) {
    throw new Error("Please select a file.");
  }

  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await axios.post(`${proxy}/file/uploadFile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error uploading file:", error);
    if (error.response) {
      // Server responded with a status other than 200 range
      throw new Error(error.response.data.message || "Error uploading file.");
    } else if (error.request) {
      // Request was made but no response received
      throw new Error("No response from server.");
    } else {
      // Something happened in setting up the request
      throw new Error("Error in setting up the request.");
    }
  }
};
