import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import Controls from "../../components/assests/Controls";
import { UseForm, Form } from "../../components/assests/UseForm";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import AppTheme from "../../Theme/AppTheme";
import axios from "axios";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";

const proxy = process.env.REACT_APP_PROXY;

const userFValues = {
  userID: 0,
  name: "",
  email: "",
  department: "",
  Role_ID: "",
};

function generateRandomPassword() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^&*()_+123456789";
  let password = "";

  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }
  return password;
}

export default function UserForm(props) {
  const { recordForEdit, onSubmit, showNotification } = props;
  const [password, setPassword] = useState(
    recordForEdit ? "" : generateRandomPassword()
  );
  const [departments, setDepartments] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false); // Loading state

  const { values, setValues, errors, handleInputChange, resetForm } = UseForm(
    recordForEdit || userFValues,
    true
  );

  useEffect(() => {
    if (recordForEdit) {
      setValues({
        userID: recordForEdit.userID,
        name: recordForEdit.name,
        email: recordForEdit.email,
        department: recordForEdit.department,
        role: recordForEdit.Role_ID,
      });
    } else {
      resetForm();
    }
  }, [recordForEdit, setValues]);

  useEffect(() => {
    const fetchDepartments = async () => {
      try {
        const response = await axios.get(`${proxy}/department/getDep`);
        setDepartments(response.data);
      } catch (error) {}
    };
    fetchDepartments();
  }, []);

  useEffect(() => {
    const fetchRoles = async () => {
      try {
        const response = await axios.get(`${proxy}/users/getroles`);
        setRoles(response.data);
      } catch (error) {}
    };
    fetchRoles();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const userData = {
      userID: recordForEdit ? recordForEdit.userID : null,
      name: values.name,
      email: values.email,
      password: password,
      Department_ID: values.department,
      role: values.role,
    };
    try {
      if (recordForEdit) {
        const res = await axios.put(`${proxy}/users/updateUser`, userData);
      } else {
        const res = await axios.post(`${proxy}/auth/register`, userData);
      }
      onSubmit();
      showNotification({
        isOpen: true,
        message: recordForEdit
          ? "User updated successfully!"
          : "User submitted successfully!",
        type: "success",
      });
      props.setOpenPopup(false); // Close the popup after submission
    } catch (error) {
      if (error.response.status === 409) {
        showNotification({
          isOpen: true,
          message: "User already exists.",
          type: "warning",
        });
      } else {
        showNotification({
          isOpen: true,
          message: "Failed to submit user data.",
          type: "error",
        });
      }
    } finally {
      setLoading(false); // Set loading back to false when done
    }
  };
  const handleReset = () => {
    resetForm();
    setPassword(generateRandomPassword());
  };

  return (
    <AppTheme>
      <Backdrop
        open={loading}
        style={{
          color: "#fff",
          zIndex: 1300,
        }}
      >
        <CircularProgress color="inherit" size="60px" />
      </Backdrop>
      <Form onSubmit={handleSubmit}>
        <Grid container>
          <Grid item xs={12}>
            <FormControl fullWidth variant="filled" required>
              <InputLabel>Department</InputLabel>
              <Select
                placeholder="Department"
                name="department"
                value={
                  departments.some(
                    (dept) => dept.Department_ID === values.department
                  )
                    ? values.department
                    : ""
                }
                onChange={handleInputChange}
                sx={{ minWidth: 250 }}
              >
                {departments.map((dept) => (
                  <MenuItem key={dept.Department_ID} value={dept.Department_ID}>
                    {dept.Department_Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Controls.Input
              required
              name="name"
              placeholder="User Name"
              value={values.name}
              onChange={handleInputChange}
              error={errors.name}
              fullWidth
              autoFocus
              margin="normal"
            />
            <Controls.Input
              required
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleInputChange}
              error={errors.email}
              fullWidth
              margin="normal"
              type="email"
            />
            {!recordForEdit && (
              <Controls.Input
                name="password"
                placeholder="Password"
                value={password}
                fullWidth
                margin="normal"
                type="text"
                disabled
              />
            )}

            <FormControl fullWidth variant="filled" required margin="normal">
              <InputLabel>Roles</InputLabel>
              <Select
                placeholder="Role"
                name="role"
                value={values.role || ""}
                onChange={handleInputChange}
                sx={{ minWidth: 250 }}
              >
                {roles.map((role) => (
                  <MenuItem key={role.Role_ID} value={role.Role_ID}>
                    {role.Role_Name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <div>
                <Controls.Button
                  type="submit"
                  text="Submit"
                  style={{ borderRadius: "8px" }}
                />
                <Controls.Button
                  text="Reset"
                  onClick={handleReset}
                  color="default"
                  style={{ borderRadius: "8px" }}
                />
              </div>
            </div>
          </Grid>
        </Grid>
      </Form>
    </AppTheme>
  );
}
